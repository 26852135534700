import React from 'react';
import { OvertimeChart } from './OvertimeChart';
import { YearOverYearChart } from './YearOverYearChart';
import { InfoAboutProduct } from './InfoAboutProduct';

export const ChartsSection = () => (
    <div className="flex-1 overflow-auto px-11 pt-5">
        <div className="grid grid-cols-2 gap-4">
            <OvertimeChart />
            <YearOverYearChart />
        </div>
        <InfoAboutProduct />
    </div>
);
