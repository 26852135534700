import { FullPageChat } from 'andromeda/ChatInterface/fullPageChat';
import { Dispatch, FC, SetStateAction } from 'react';
import { useHermesChat } from '@/hooks/useHermesChat';
import { useHermesMetadata } from './useHermesMetadata';
import { fullPageChatSuggestions } from './mockdata';

interface HermesHomeFullPageChatProps {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
}

export const HermesHomeFullPageChat: FC<HermesHomeFullPageChatProps> = ({
    visible,
    setVisible,
}) => {
    const { brand } = useHermesMetadata();
    const { chatLoading, sendChat } = useHermesChat();
    return (
        <FullPageChat
            opened={visible}
            onClose={() => setVisible(false)}
            brand={brand}
            suggestions={fullPageChatSuggestions(brand)}
            chatLoading={chatLoading}
            sendChat={sendChat}
            title="Hermes"
        />
    );
};
