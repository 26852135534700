import React from 'react';
import moment from 'moment';
import { Divider, Skeleton } from '@mantine/core';
import { useProductAnalysisContext } from '../context/ProductAnalysisContextProvider';
import { getProductNameWithSizeFromProductList } from '../utils';

export const InfoAboutProduct = () => {
    const {
        selectedProducts,
        productList,
        competitors,
        timeseriesAnalysisData,
        timeseriesAnalysisIsLoading,
    } = useProductAnalysisContext();

    const today = moment();
    const lastMonday = today
        .clone()
        .day(today.day() >= 1 ? 1 : -6)
        .format('MMM D, YYYY');

    if (timeseriesAnalysisIsLoading) {
        return <Skeleton className="!mt-4 ml-auto !h-4 !w-[200px]" />;
    }

    return (
        <div className="my-8 flex justify-end gap-4">
            {selectedProducts.map(product => (
                <div key={product} className="flex flex-col gap-1">
                    <div className="text-xs text-navy-70">
                        {getProductNameWithSizeFromProductList(productList, competitors, product)}
                    </div>
                    <Divider />
                    <div className="flex items-center gap-px">
                        <span className="text-xs text-navy-50">Datasource:</span>
                        <span className="text-xs text-navy-70">
                            {timeseriesAnalysisData?.[product]?.datasource}
                        </span>
                    </div>
                    <div className="flex items-center gap-px">
                        <span className="text-xs text-navy-50">Markets:</span>
                        <span className="text-xs text-navy-70">
                            {timeseriesAnalysisData?.[product]?.markets}
                        </span>
                    </div>
                    <div className="flex items-center gap-px">
                        <span className="text-xs text-navy-50">Last datapoint:</span>
                        <span className="text-xs text-navy-70">
                            {moment(timeseriesAnalysisData?.[product]?.last_updated_date).format(
                                'MMM D, YYYY',
                            )}
                        </span>
                    </div>
                    <div className="flex items-center gap-px">
                        <span className="text-xs text-navy-50">Last access date:</span>
                        <span className="text-xs text-navy-70">{lastMonday}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};
