import React from 'react';
import ResponsiveLayout from '@/components/responsive/ResponsiveLayout';
import { MarketPerformanceProvider } from '@/components/phobos/MarketPerformance/context/MarketPerformanceContextProvider';
import { PROXIMO_USER_GROUP_WHITELIST } from '@/components/navBar/constants';
import { UserGuard } from '@/components/utils/UserGuard';
import MarketPerformancePage from '../components/phobos/MarketPerformance';

function MarketPerformance() {
    return (
        <ResponsiveLayout
            title="Market Performance"
            className="!mb-0 !flex !h-screen !flex-col !p-0"
        >
            <UserGuard userGroupWhitelist={PROXIMO_USER_GROUP_WHITELIST}>
                <MarketPerformanceProvider>
                    <MarketPerformancePage />
                </MarketPerformanceProvider>
            </UserGuard>
        </ResponsiveLayout>
    );
}

export default MarketPerformance;
