import { useMemo } from 'react';
import { SelectsDataType } from 'andromeda/types/select/common';
import { getCompetitorOptions, getProductOptions } from '../utils';
import { useProductAnalysisContext } from '../context/ProductAnalysisContextProvider';

export const useDropdownOptions = () => {
    const { productList, competitors } = useProductAnalysisContext();

    const productOptions = useMemo(
        (): SelectsDataType[] => getProductOptions(productList),
        [productList],
    );

    const competitorOptions = useMemo(
        (): SelectsDataType[] => getCompetitorOptions(competitors),
        [competitors],
    );

    const secondProductDropdownOptions = useMemo(
        (): SelectsDataType[] => [...productOptions, ...competitorOptions],
        [productOptions, competitorOptions],
    );

    return {
        productOptions,
        competitorOptions,
        secondProductDropdownOptions,
    };
};
