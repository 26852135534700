import React, { createContext, FunctionComponent, ReactNode, useContext, useMemo } from 'react';
import { PhobosProduct } from '@/types/PhobosProduct';
import { useProductAnalysisQueryParams } from '../hooks/useProductAnalysisQueryParams';
import { useProductList } from '../hooks/useProductList';
import { useCompetitorsData } from '../hooks/useCompetitorsData';
import { CompetitorsDataResponse } from '../types';
import { useTimeseriesAnalysisData } from '../hooks/useTimeseriesAnalysisData';
import { getMinDateByTimeframe } from '../utils';

interface ProductAnalysisInterface {
    metrics: string[];
    updateMetrics: (value: string[]) => void;
    product: string;
    updateProduct: (value: string) => void;
    secondProduct: string;
    updateSecondProduct: (value: string) => void;
    timeframe: string;
    updateTimeframe: (value: string) => void;
    state: string;
    updateState: (value: string) => void;
    productList: PhobosProduct[];
    isProductListLoading: boolean;
    selectedProducts: string[];
    competitors: CompetitorsDataResponse;
    competitorsIsLoading: boolean;
    timeseriesAnalysisData: any;
    timeseriesAnalysisIsLoading: boolean;
}

const ProductAnalysisContext = createContext<ProductAnalysisInterface | null>(null);

interface ProductAnalysisProviderProps {
    children: ReactNode;
}

const ProductAnalysisProvider: FunctionComponent<ProductAnalysisProviderProps> = ({ children }) => {
    const {
        queryParams,
        updateMetrics,
        updateProduct,
        updateTimeframe,
        updateState,
        updateSecondProduct,
    } = useProductAnalysisQueryParams();
    const { metrics, product, timeframe, state, secondProduct } = queryParams;
    const { data: productList, isLoading: isProductListLoading } = useProductList();
    const { data: competitors, isLoading: competitorsIsLoading } = useCompetitorsData({
        UPC: product,
    });
    const selectedProducts = [product, secondProduct].filter(Boolean);
    const { data: timeseriesAnalysisData, isLoading: timeseriesAnalysisIsLoading } =
        useTimeseriesAnalysisData({
            UPCS: selectedProducts,
            state,
            metrics,
            min_date: getMinDateByTimeframe(timeframe),
        });

    const value = useMemo(
        () => ({
            metrics,
            updateMetrics,
            product,
            updateProduct,
            secondProduct,
            updateSecondProduct,
            timeframe,
            updateTimeframe,
            state,
            updateState,
            productList,
            isProductListLoading,
            selectedProducts,
            competitors,
            competitorsIsLoading,
            timeseriesAnalysisData,
            timeseriesAnalysisIsLoading,
        }),
        [
            metrics,
            updateMetrics,
            product,
            updateProduct,
            secondProduct,
            updateSecondProduct,
            timeframe,
            updateTimeframe,
            state,
            updateState,
            productList,
            isProductListLoading,
            competitors,
            competitorsIsLoading,
            selectedProducts,
            timeseriesAnalysisData,
            timeseriesAnalysisIsLoading,
        ],
    );

    return (
        <ProductAnalysisContext.Provider value={value}>{children}</ProductAnalysisContext.Provider>
    );
};

const useProductAnalysisContext = (): ProductAnalysisInterface => {
    const context = useContext(ProductAnalysisContext);
    if (!context) {
        throw new Error('useProductAnalysis must be used within a ProductAnalysisProvider');
    }
    return context;
};

export { ProductAnalysisProvider, useProductAnalysisContext };
