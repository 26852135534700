import React from 'react';
import ResponsiveLayout from '@/components/responsive/ResponsiveLayout';
import { ProductAnalysisProvider } from '@/components/phobos/ProductAnalysis/context/ProductAnalysisContextProvider';
import { PROXIMO_USER_GROUP_WHITELIST } from '@/components/navBar/constants';
import { UserGuard } from '@/components/utils/UserGuard';
import ProductAnalysisPage from '../components/phobos/ProductAnalysis';

const ProductAnalysis = () => (
    <ResponsiveLayout title="Product Analysis" className="!mb-0 !flex !h-screen !flex-col !p-0">
        <UserGuard userGroupWhitelist={PROXIMO_USER_GROUP_WHITELIST}>
            <ProductAnalysisProvider>
                <ProductAnalysisPage />
            </ProductAnalysisProvider>
        </UserGuard>
    </ResponsiveLayout>
);

export default ProductAnalysis;
