import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Skeleton, Table } from '@mantine/core';
import classNames from 'classnames';
import StyledTable from 'andromeda/styledTable';
import { handleCellClickType, SortingType } from 'andromeda/tableHeaderCellCarret';
import NoDataCard from 'andromeda/noDataCard';
import Pagination from 'andromeda/Pagination';
import {
    MultiLevelMultiSelectType,
    NewSelectPossibleValues,
    NewTypeSelectData,
    RangeInputDataType,
} from 'andromeda/types/select/newTypeOfSelect';
import { PHNestleInspectDataType } from '../../hooks/useInspectNamespaceData';
import { formatNumber } from '../../utils/numberFormatHelpers';
import TableHeaderCell, {
    InvoiceTableHeadersKeys,
} from '../pricingIntelligence/pricingDetails/table/tableHeaderCell';

import { InvoiceTableFilters } from './utils';
import { fusionMoment } from '../../utils/dateHelpers';

const numbersHeaders: InvoiceTableHeadersKeys[] = [
    'num_stores_ordered',
    'num_orders',
    'gross_revenue',
    'avg_range_purchased',
    'avg_range_recommended',
    'orders_influenced',
    'precision',
    'recall',
    'mape',
];

const headersKeys: Partial<Record<InvoiceTableHeadersKeys, string>> = {
    num_stores_ordered: '# Stores',
    num_orders: '# Orders',
    gross_revenue: 'Gross Revenue',
    avg_range_purchased: 'Average range purchased',
    avg_range_recommended: 'Average range recommended',
    orders_influenced: '% Orders Influenced',
    precision: 'Precision',
    recall: 'Recall',
    mape: 'MAPE',
};

const numbersNamesToHeaders: Partial<Record<string, InvoiceTableHeadersKeys>> = {
    '# Stores': 'num_stores_ordered',
    '# Orders': 'num_orders',
    'Gross Revenue': 'gross_revenue',
    '% Orders Influenced': 'orders_influenced',
    Precision: 'precision',
    'Average range purchased': 'avg_range_purchased',
    'Average range recommended': 'avg_range_recommended',
    Recall: 'recall',
    MAPE: 'mape',
};
const columnsFormatters: Partial<Record<InvoiceTableHeadersKeys, (n: number | string) => string>> =
    {
        num_stores_ordered: n => formatNumber(n, '0,0'),
        num_orders: n => formatNumber(n, '0,0'),
        gross_revenue: n => `₱${formatNumber(n, '0a')}`,
        orders_influenced: n => formatNumber(n, '0.0%'),
        precision: n => formatNumber(n, '0.0%'),
        recall: n => formatNumber(n, '0.0%'),
        mape: n => formatNumber(n, '0.0%'),
        date: (n: string) => fusionMoment(n).format('MMM DD YYYY'),
        avg_range_purchased: n => formatNumber(n, '0,0.00'),
        avg_range_recommended: n => formatNumber(n, '0,0.00'),
    };

function InspectTable({
    data = [],
    loading,
    mappingGroupByKeys,
    getTableFilters,
    filters,
}: {
    data: PHNestleInspectDataType;
    loading: boolean;
    mappingGroupByKeys: Record<string, string>;
    getTableFilters: (filters: NewTypeSelectData[]) => void;
    filters: Partial<
        Record<string | InvoiceTableFilters | 'tableGroupBy', NewSelectPossibleValues>
    >;
}) {
    const headersOrder: InvoiceTableHeadersKeys[] = useMemo(
        () =>
            Array.from(
                new Set([
                    'date',
                    ...(Object.keys(mappingGroupByKeys) as InvoiceTableHeadersKeys[]),
                    'num_stores_ordered',
                    'num_orders',
                    'gross_revenue',
                    'avg_range_purchased',
                    'avg_range_recommended',
                    'orders_influenced',
                    'precision',
                    'recall',
                    'mape',
                ]),
            ),
        [mappingGroupByKeys],
    );

    const groupBy = filters?.tableGroupBy?.value as string;
    const [page, setPage] = useState(1);
    const [tableHeaderHasFocus, setTableHeaderHasFocus] = useState<Record<string, boolean>>();
    const [sorting, setSorting] = useState<{
        column: InvoiceTableHeadersKeys;
        type: SortingType;
    }>({
        column: 'gross_revenue',
        type: 'asc',
    });
    const tableRef = useRef<HTMLDivElement>(null);
    const nPerPage = Math.round(((tableRef.current?.clientHeight ?? 1) - 63.5 - 34) / 35);

    useEffect(() => {
        setPage(1);
    }, [loading]);

    const tablesNumbersFiltersData = useCallback(
        () =>
            data?.length === 0
                ? {}
                : data?.reduce?.(
                      (acc: Record<string, RangeInputDataType>, obj) => ({
                          ...numbersHeaders.reduce(
                              (acc2: Record<string, RangeInputDataType>, item) => {
                                  const n = obj[item];
                                  const key = headersKeys[item];

                                  if (typeof n === 'number')
                                      return {
                                          ...acc2,
                                          [key]: {
                                              ...acc[key],
                                              data: {
                                                  max:
                                                      acc[key].data?.max < n && !!n
                                                          ? n
                                                          : acc[key].data?.max,
                                                  min:
                                                      acc[key].data?.min > n && !!n
                                                          ? n
                                                          : acc[key].data?.min,
                                              },
                                          } as RangeInputDataType,
                                      };
                                  return acc;
                              },
                              {},
                          ),
                      }),
                      numbersHeaders.reduce(
                          (acc, item) => ({
                              ...acc,
                              [headersKeys[item]]: {
                                  type: 'range',
                                  numberFormate: columnsFormatters[item],
                                  formateValueForDisplay: v =>
                                      v.map(columnsFormatters[item]).join(' ↔ '),
                                  data: {
                                      max: Number.MIN_SAFE_INTEGER,
                                      min: Number.MAX_SAFE_INTEGER,
                                  },
                              } as RangeInputDataType,
                          }),
                          {},
                      ),
                  ) ?? {},
        [data],
    );

    const tablesStringFiltersData = useCallback(
        () =>
            data?.length === 0
                ? {}
                : data?.reduce?.(
                      (acc: Record<string, MultiLevelMultiSelectType>, obj) => ({
                          ...acc,
                          ...Object.entries(mappingGroupByKeys).reduce(
                              (acc2: Record<string, MultiLevelMultiSelectType>, [key, accKey]) =>
                                  ['Date'].includes(accKey) ||
                                  typeof obj?.[key] !== 'string' ||
                                  acc?.[accKey]?.data?.includes(obj?.[key])
                                      ? acc2
                                      : {
                                            ...acc2,
                                            [accKey]: {
                                                type: 'multiSelect',
                                                data: [
                                                    ...(acc?.[accKey]?.data ?? []),
                                                    obj[key],
                                                ].sort((a: string, b: string) =>
                                                    a.localeCompare(b),
                                                ),
                                                isSelectAll: true,
                                            } as MultiLevelMultiSelectType,
                                        },
                              {},
                          ),
                      }),
                      {},
                  ) ?? {},
        [data, mappingGroupByKeys],
    );

    useEffect(() => {
        if (loading) return;
        if (data.length === 0) getTableFilters([]);
        const strings = tablesStringFiltersData();
        const numbers = tablesNumbersFiltersData();
        getTableFilters([strings, numbers]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.length, loading]);

    const filteredData = useMemo(() => {
        if (Object.keys(filters).length === 0) return data;
        return (
            data?.filter?.(item =>
                Object.entries(filters).reduce((acc, [key, val]) => {
                    const v =
                        item[
                            {
                                ...numbersNamesToHeaders,
                                ...Object.entries(mappingGroupByKeys ?? {}).reduce(
                                    (accX: Record<string, string>, [k, vv]) => ({
                                        ...accX,
                                        [vv]: k,
                                    }),
                                    {},
                                ),
                            }[key]
                        ];
                    if (val.type === 'range' && typeof v === 'number')
                        return acc && v >= val.value[0] && v <= val.value[1];
                    if (val.type === 'multiSelect' && typeof v === 'string')
                        return acc && val.value.includes(v);
                    if (val.type === 'select' && typeof v === 'string')
                        return acc && val.value === v;
                    return acc;
                }, true),
            ) ?? []
        );
    }, [data, filters, mappingGroupByKeys]);

    const tableBody = useMemo(
        () =>
            filteredData
                .sort((a, b) => {
                    const column = sorting.column;
                    let aX = a[column];
                    let bX = b[column];

                    if (typeof aX === 'string' && typeof bX === 'string') {
                        if (column === 'date') {
                            if (sorting.type === 'asc')
                                return new Date(aX).getTime() - new Date(bX).getTime();
                            return new Date(bX).getTime() - new Date(aX).getTime();
                        }
                        if (sorting.type === 'asc')
                            return (aX || 'not available')?.localeCompare(bX || 'not available');
                        return (bX || 'not available')?.localeCompare(aX || 'not available');
                    }
                    aX = a[column] ?? 0;
                    bX = b[column] ?? 0;
                    if (typeof aX === 'number' && typeof bX === 'number') {
                        if (sorting.type === 'asc') return bX - aX;
                        return aX - bX;
                    }
                    return 1 - 0;
                })
                .map((row, rowI) => (
                    <Table.Tr key={`${row.date}${rowI * 1}`} className="h-fit">
                        {headersOrder.map((column, columnI) => {
                            const columnValue = row[column];
                            return (
                                Object.keys(data?.[0] ?? {}).includes(column) && (
                                    <Table.Td
                                        key={`${column + columnValue}${rowI * 2 + columnI * 2}`}
                                        className="h-fit !text-center"
                                    >
                                        {columnsFormatters?.[column]?.(columnValue) ??
                                            columnValue ??
                                            'N/A'}
                                    </Table.Td>
                                )
                            );
                        })}
                    </Table.Tr>
                )),
        [data, filteredData, headersOrder, sorting.column, sorting.type],
    );

    const paginatedBody = useMemo(
        () =>
            tableBody.length <= nPerPage
                ? tableBody
                : [...tableBody].slice((page - 1) * nPerPage, page * nPerPage),
        [nPerPage, page, tableBody],
    );
    const total = Math.ceil(tableBody.length / nPerPage);

    if (!groupBy) {
        return (
            <div className="flex h-[400px] items-center justify-center">
                <NoDataCard>Select a group by filter</NoDataCard>
            </div>
        );
    }
    return (
        <div
            ref={tableRef}
            className={classNames('min-h-[40dvh] w-full overflow-hidden', {
                'flex flex-col': paginatedBody.length === 0 || loading,
                'items-center justify-center': paginatedBody.length === 0,
            })}
            style={{ height: 'calc(100dvh - 257px)' }}
        >
            <StyledTable
                spaceportBareCardClassName="rounded"
                body={paginatedBody}
                noDataText="No data available, please contact abi-ghq-support@arena-ai.com for more
                    information."
                dataCondition={paginatedBody.length === 0}
                headers={headersOrder.map(key => (
                    <Table.Th key={key} className="min-w-[80px] !text-center">
                        <TableHeaderCell<InvoiceTableHeadersKeys>
                            cell={key}
                            onClick={() => {
                                setSorting({
                                    column: key,
                                    type: handleCellClickType(
                                        (key === sorting.column && sorting.type) || false,
                                    ),
                                });
                                setPage(1);
                            }}
                            sorting={sorting}
                            handleFocus={obj =>
                                setTableHeaderHasFocus(prev => ({
                                    ...prev,
                                    ...obj,
                                }))
                            }
                            focusBank={tableHeaderHasFocus}
                            className="flex w-full items-center justify-center text-center"
                        >
                            {headersKeys?.[key] ?? mappingGroupByKeys?.[key] ?? key}
                        </TableHeaderCell>
                    </Table.Th>
                ))}
                maxHeight={loading ? 'calc(100vh - 295px)' : 'calc(100vh - 295px)'}
                minHeight={loading ? 'calc(100vh - 295px)' : 'calc(100vh - 295px)'}
                loading={loading}
            />
            <div className="flex w-full justify-center">
                {loading ? (
                    <Skeleton style={{ height: 32 }} className="min-h-8 !w-full max-w-[360px]" />
                ) : (
                    total > 1 && <Pagination total={total} page={page} onChange={setPage} />
                )}
            </div>
        </div>
    );
}

export default InspectTable;
