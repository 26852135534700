import { Image } from '@mantine/core';

const OrbyLoader = ({ className, size = 24 }: { className?: string; size?: number }) => (
    <Image
        src="/images/orbyLoader.gif"
        width={size}
        height={size}
        alt="orby-loader"
        className={className}
        styles={{
            root: {
                display: 'inline',
                width: `${size}px`,
            },
        }}
    />
);

export default OrbyLoader;
