import { Title } from '@mantine/core';
import React, { PropsWithChildren } from 'react';
import { ArrowRight } from 'phosphor-react';
import { useRouter } from 'next/router';
import { ProductResults } from './ProductResults';
import { MarketInsights } from './MarketInsights';
import { useProductSearchQueryParams } from './useProductSearchQueryParams';
import { useAlertsByQueryParams } from '../useAlertsByQueryParams';
import { useProductListByQueryParams } from '../useProductListByQueryParams';

interface ShowAllButtonProps {
    onClick: () => void;
}

const ShowAllButton: React.FC<PropsWithChildren<ShowAllButtonProps>> = ({ onClick, children }) => (
    <button
        type="button"
        className="flex items-center gap-2 text-xs text-primary-50 transition-colors hover:text-primary"
        onClick={onClick}
    >
        {children}
        <ArrowRight size={16} />
    </button>
);

export const ProductHome = () => {
    const router = useRouter();
    const { brand, size, state } = useProductSearchQueryParams();

    const { data: productList } = useProductListByQueryParams();
    const { data: alerts } = useAlertsByQueryParams();

    const onShowAllInsights = () => {
        const searchParams = new URLSearchParams();
        if (brand) {
            searchParams.set('brand', brand);
        }
        if (size) {
            searchParams.set('size', size);
        }
        if (state) {
            searchParams.set('state', state);
        }
        router.push(`/pricing/insights?${searchParams.toString()}`);
    };

    const onShowAllProducts = () => {
        const searchParams = new URLSearchParams();
        if (brand) {
            searchParams.set('brand', brand);
        }
        if (size) {
            searchParams.set('size', size);
        }
        if (state) {
            searchParams.set('state', state);
        }
        router.push(`/pricing/products?${searchParams.toString()}`);
    };

    return (
        <div className="mt-8 flex flex-col gap-10">
            <div className="flex basis-1/3 flex-col">
                <div className="flex items-center justify-between">
                    <Title order={3} className="!text-primary-50">
                        Market Insights{' '}
                        {typeof alerts?.length === 'number' && ` (${alerts?.length})`}
                    </Title>
                    <ShowAllButton onClick={onShowAllInsights}>
                        Show all insights and alerts
                    </ShowAllButton>
                </div>
                <MarketInsights />
            </div>
            <div className="flex basis-2/3 flex-col">
                <div className="flex items-center justify-between">
                    <Title order={3} className="!text-primary-50">
                        {productList?.length
                            ? ` Your Products${typeof productList.length === 'number' && ` (${productList.length})`}`
                            : `Top Products`}
                    </Title>
                    <ShowAllButton onClick={onShowAllProducts}>Show all products</ShowAllButton>
                </div>
                <ProductResults />
            </div>
        </div>
    );
};
