import useRouterQuery from '@/hooks/useRouterQuery';
import { MarketPerformancePageQueryParams } from '../types';
import { TIMEFRAME_OPTIONS } from '../constants';

export const useMarketPerformanceQueryParams = () => {
    const { handleURLParamsChange, router } = useRouterQuery();
    const { path, timeframe } = router.query as MarketPerformancePageQueryParams;

    const updateTimeframe = (value: string) => {
        handleURLParamsChange({ key: 'timeframe', value });
    };

    const updateMetric = (value: string) => {
        handleURLParamsChange({ key: 'metric', value });
    };

    const updatePath = (value: string[]) => {
        handleURLParamsChange({ key: 'path', value });
    };

    const pathValue = (function () {
        if (!path) return [];
        if (typeof path === 'string') return [path];
        return path;
    })();

    return {
        queryParams: {
            ...(router.query as MarketPerformancePageQueryParams),
            path: pathValue,
            timeframe: timeframe ?? TIMEFRAME_OPTIONS[2].value,
        },
        updateTimeframe,
        updateMetric,
        updatePath,
    };
};
