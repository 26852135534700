import { PROMO_API_URL } from '../buildConfig/processEnv';
import { PhobosProduct } from '../types/PhobosProduct';
import useApiQuery from './useApiQuery';

interface UseProductListParams {
    query?: string;
    amount?: string;
    category?: string;
}

function useProductList({ query, amount, category }: UseProductListParams) {
    return useApiQuery<PhobosProduct[]>({
        url: `${PROMO_API_URL}/promo-management/api/promo-management/query_product`,
        method: 'POST',
        body: { query, size: amount, category },
        // disabled: !query && !amount,
    });
}

export default useProductList;
