import React from 'react';
import { Divider } from '@mantine/core';
import { primaryColors } from '@/constants/colors';
import { TimeframeToggle } from './TimeframeToggle';
import { MultiselectToggle } from './MultiselectToggle';
import { useProductAnalysisContext } from '../context/ProductAnalysisContextProvider';
import { getMultiSelectToggleMetricOptions } from '../utils';

export const TogglesSection = () => {
    const { metrics, updateMetrics, timeseriesAnalysisData } = useProductAnalysisContext();
    const metricOptions = getMultiSelectToggleMetricOptions(
        timeseriesAnalysisData?.metric_info.description,
    );

    return (
        <div className="mt-6 flex items-center gap-4">
            <MultiselectToggle
                title="Metrics"
                items={metricOptions}
                onToggleChange={activeToggles =>
                    updateMetrics(activeToggles.map(item => item.value))
                }
                defaultValue={metrics}
                selectedBgColor={primaryColors.blue}
                selectedTextColor="#fff"
            />
            <Divider className="h-8 w-px bg-navy-solid-15" />
            <TimeframeToggle />
        </div>
    );
};
