import useRouterQuery from '../../../hooks/useRouterQuery';

export const useProductSearchQueryParams = () => {
    const { handleURLParamsChange, router } = useRouterQuery();

    const brand = router.query.brand as string;
    const size = router.query.size as string;
    const state = router.query.state as string;

    const updateBrand = (value?: string) => {
        handleURLParamsChange({ key: 'brand', value });
    };

    const updateSize = (value?: string) => {
        handleURLParamsChange({ key: 'size', value });
    };

    const updateState = (value?: string) => {
        handleURLParamsChange({ key: 'state', value });
    };

    return {
        brand,
        size,
        state,
        updateBrand,
        updateSize,
        updateState,
    };
};
