import { Dispatch, useEffect } from 'react';
import { calculateVisibleCards } from '../utils';

export const useCardsDisplay = (
    setVisibleCardsCountToShow: Dispatch<React.SetStateAction<number>>,
) => {
    useEffect(() => {
        // Function to update the number of visible cards based on container width
        const updateVisibleCards = () => {
            const categoriesContainer = document.getElementById('cards-display-container');
            if (!categoriesContainer) return;

            const visible = calculateVisibleCards(categoriesContainer.clientWidth);
            setVisibleCardsCountToShow(visible);
        };

        // Call once to set initial state
        updateVisibleCards();

        // Event listener for window resize to adjust visible cards dynamically
        window.addEventListener('resize', updateVisibleCards);

        // Cleanup function to remove event listener when component unmounts or updates
        return () => window.removeEventListener('resize', updateVisibleCards);
    }, [setVisibleCardsCountToShow]);
};
