import React, { useRef, useState } from 'react';
import Select from 'andromeda/selects/select';
import { Button, Checkbox } from 'andromeda';
import { useProductAnalysisContext } from '../context/ProductAnalysisContextProvider';
import { SELECT_STYLE_VALUE } from '../constants';
import { useDropdownOptions } from '../hooks/useDropdownOptions';
import { SelectedStatesType } from '../types';
import { getStateData } from '../utils';

export const DropdownsSection = () => {
    const {
        product,
        updateProduct,
        secondProduct,
        updateSecondProduct,
        state,
        updateState,
        isProductListLoading,
        competitorsIsLoading,
    } = useProductAnalysisContext();
    const [showSecondProductListDropdown, setShowSecondProductListDropdown] =
        useState(!!secondProduct);
    const secondProductInputRef = useRef<HTMLInputElement>(null);
    const { productOptions, secondProductDropdownOptions } = useDropdownOptions();
    const [selectedStates, setSelectedStates] = useState<SelectedStatesType>({
        controlStates: true,
        openStates: true,
    });

    const handleAddAComparisonBtnOnClick = () => {
        setShowSecondProductListDropdown(true);
        setTimeout(() => {
            secondProductInputRef.current?.focus();
            secondProductInputRef.current?.click();
        }, 100);
    };

    const stateData = getStateData(selectedStates);

    return (
        <div className="mt-6">
            <div className="flex items-center gap-4">
                <div className="flex items-center gap-4">
                    <Select
                        key={`select-${product}`}
                        placeholder="Search for a product"
                        data={productOptions}
                        clearable
                        value={product}
                        onChange={updateProduct}
                        width={350}
                        loading={isProductListLoading}
                        dropdownClassName="!max-h-[432px]"
                        dropdownStyle={SELECT_STYLE_VALUE}
                    />
                    {showSecondProductListDropdown && (
                        <Select
                            ref={secondProductInputRef}
                            key={`select-${secondProduct}`}
                            placeholder="Search for a product"
                            data={secondProductDropdownOptions}
                            clearable
                            value={secondProduct}
                            onChange={updateSecondProduct}
                            width={350}
                            loading={isProductListLoading || competitorsIsLoading}
                            clearButtonOnClick={() => setShowSecondProductListDropdown(false)}
                            dropdownClassName="!max-h-[432px] !w-[450px] !max-w-[410px]"
                            dropdownStyle={SELECT_STYLE_VALUE}
                        />
                    )}
                </div>
                {!showSecondProductListDropdown && (
                    <Button onClick={handleAddAComparisonBtnOnClick}>Add a Comparison</Button>
                )}
                <Select
                    key={`select-${state}`}
                    placeholder="State"
                    data={stateData}
                    clearable
                    value={state}
                    width={140}
                    onChange={updateState}
                    dropdownStyle={SELECT_STYLE_VALUE}
                />
                <div className="ml-4 flex items-center gap-2">
                    <Checkbox
                        label="Control States"
                        checked={selectedStates.controlStates}
                        onChange={() =>
                            setSelectedStates({
                                ...selectedStates,
                                controlStates: !selectedStates.controlStates,
                            })
                        }
                    />
                    <Checkbox
                        label="Open States"
                        checked={selectedStates.openStates}
                        onChange={() =>
                            setSelectedStates({
                                ...selectedStates,
                                openStates: !selectedStates.openStates,
                            })
                        }
                    />
                </div>
            </div>
        </div>
    );
};
