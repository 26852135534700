import React from 'react';
import { ArrowsCounterClockwise } from 'phosphor-react';
import { useMarketPerformanceContext } from '../context/MarketPerformanceContextProvider';
import { SelectedCard } from './SelectedCard';
import { getCategoryAndValue, getSelectedCardPath } from '../utils';

export const DeepDive = () => {
    const { updatePath, selectedPath, selectedTimeframe } = useMarketPerformanceContext();
    const handleResetPath = () => {
        updatePath(null);
    };
    return (
        <div className="overflow-auto pl-11 pr-4">
            <div className="mt-6 flex items-center justify-between">
                <div className="w-[158px] text-xs font-medium text-navy">Deep Dive</div>
                <button type="button" onClick={handleResetPath}>
                    <div className="group flex items-center gap-1">
                        <ArrowsCounterClockwise
                            className="text-navy-50 group-hover:text-navy"
                            size={16}
                        />
                        <div className="text-xs font-medium text-navy-50 group-hover:text-navy">
                            Reset
                        </div>
                    </div>
                </button>
            </div>
            <div className="mt-4 flex flex-col gap-2">
                <SelectedCard
                    cardValue="US"
                    cardCategory="total_us"
                    path={[]}
                    timescale={selectedTimeframe}
                    onClick={() => updatePath(null)}
                />
                {selectedPath.map((path, index) => (
                    <SelectedCard
                        key={path}
                        cardValue={getCategoryAndValue(path).value}
                        cardCategory={getCategoryAndValue(path).category}
                        path={getSelectedCardPath(index, selectedPath)}
                        timescale={selectedTimeframe}
                        onClick={() => updatePath(selectedPath.slice(0, index + 1))}
                    />
                ))}
            </div>
        </div>
    );
};
