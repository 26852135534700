import { PROMO_API_URL } from '../buildConfig/processEnv';
import useApiQuery from './useApiQuery';

export interface SummaryBrandSize {
    brand_names: string[];
    sizes: string[];
}

interface UseBrandSizeParams {
    brand?: string;
    size?: string;
}

export const useBrandSize = ({ brand, size }: UseBrandSizeParams = {}) =>
    useApiQuery<SummaryBrandSize>({
        url: `${PROMO_API_URL}/promo-management/api/promo-management/get_brand_name_sizes`,
        method: 'POST',
        body: {
            brand_name: brand,
            size,
        },
    });
