import Inspect from '@/components/inspect/inspectComponent';
import { MOST_USED_ABI_USERS } from '@/components/navBar/constants';
import ResponsiveLayout from '@/components/responsive/ResponsiveLayout';
import { UserGuard } from '@/components/utils/UserGuard';

export default function InspectPage() {
    return (
        <ResponsiveLayout title="Insights" className="md:!pb-0">
            <UserGuard userGroupWhitelist={MOST_USED_ABI_USERS}>
                <Inspect />
            </UserGuard>
        </ResponsiveLayout>
    );
}
