import dynamic from 'next/dynamic';
import ResponsiveLayout from '@/components/responsive/ResponsiveLayout';

const TBD = dynamic(() => import('../components/tbd/tbd'), { ssr: false });

export default function SearchPage() {
    return (
        <ResponsiveLayout title="Search" className="!p-0">
            <TBD />
        </ResponsiveLayout>
    );
}
