import { Toggle } from 'andromeda';
import React from 'react';
import { TIMEFRAME_OPTIONS } from '../constants';
import { useProductAnalysisContext } from '../context/ProductAnalysisContextProvider';

export const TimeframeToggle = () => {
    const { timeframe, updateTimeframe } = useProductAnalysisContext();

    return (
        <div className="flex items-center">
            <div className="mr-2 text-xs font-medium text-navy-solid-70">
                Timeframe (last months):
            </div>
            <Toggle
                options={TIMEFRAME_OPTIONS}
                defaultOption={timeframe}
                selectedBgColor="#e0e4f6"
                selectedTextColor="#515674"
                textColor="#b1b5c1"
                variant="secondary"
                onSelectionChange={(value: string) => updateTimeframe(value)}
            />
        </div>
    );
};
