/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ArrowRight, Sparkle, WarningCircle } from 'phosphor-react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import useColorScheme from '@/hooks/useColorScheme';
import { AlertItem } from './ProductSearch/useAlerts';
import { StyledPhobosCard } from './PhobosCard';

interface InsightCardProps {
    item: AlertItem;
    className?: string;
}

const InsightCard = ({ item, className }: InsightCardProps) => {
    const { colorScheme } = useColorScheme();
    const router = useRouter();

    const analysisTab = item?.analyze_messages?.[0]?.type?.replace('_', '-');

    return (
        <StyledPhobosCard
            className={classNames(
                'gradient-bg border-t border-l transition-colors p-4 rounded',
                className,
            )}
            theme={colorScheme}
        >
            <div
                className={classNames('h-24 ', { 'border-b-1 border-navy-solid-10': !item.alert })}
            >
                <div
                    className={classNames('flex flex-col items-start', {
                        'h-24 justify-between grow': item.alert,
                        'gap-[10px]': !item.alert,
                    })}
                >
                    <div className="flex">
                        {item.alert ? (
                            <WarningCircle size={16} className="text-orange-600" />
                        ) : (
                            <Sparkle size={16} className="text-risd-blue" />
                        )}
                        <h5 className="ml-2 !text-xs text-navy-solid-50">
                            {item.state} | {item.name}
                        </h5>
                    </div>
                    <h5
                        className={classNames('!text-sm', {
                            'text-orange-600': item.alert,
                            'text-risd-blue': !item.alert,
                        })}
                    >
                        {item.title}
                    </h5>
                    <div
                        className="flex cursor-pointer gap-1 text-navy-solid-50 hover:text-primary"
                        onClick={() =>
                            router.push(
                                `/pricing/${item.UPC}?screen=product-details&analysisState=${item?.state}&analysisTab=${analysisTab}`,
                            )
                        }
                    >
                        <h5 className="!text-xs">View {item.alert ? 'analysis' : 'insight'}</h5>
                        <ArrowRight
                            className={classNames({
                                'text-risd-blue': !item.alert,
                                'text-orange-600': item.alert,
                            })}
                        />
                    </div>
                </div>
            </div>
            {!item.alert &&
                item?.analyze_messages?.map(message => (
                    <div
                        className="flex cursor-pointer gap-2 pt-2 text-navy-solid-50 hover:text-primary"
                        key={message.message}
                        onClick={() =>
                            router.push(
                                `/pricing/${item.UPC}?screen=product-details&analysisState=${item?.state}&analysisTab=${message?.type}`,
                            )
                        }
                    >
                        <WarningCircle />
                        <h5 className="!text-xs">{message.message}</h5>
                        <ArrowRight />
                    </div>
                ))}
        </StyledPhobosCard>
    );
};

export default InsightCard;
