import React from 'react';
import { Categories } from './Categories';
import { DeepDive } from './DeepDive';

export const DeepDiveAndRegions = () => (
    <div className="flex flex-1 flex-row overflow-hidden">
        <DeepDive />
        <Categories />
    </div>
);
