import { StyledChartContainer } from '@/components/promos/utils';
import React from 'react';
import LineChart from '@/components/utils/charts/lineChart';
import { TooltipLabelStyle } from 'chart.js';
import { useProductAnalysisContext } from '../context/ProductAnalysisContextProvider';
import { getOvertimeChartData } from '../utils';
import { useOvertimeChartData } from '../hooks/useOvertimeChartData';
import { OvertimeChartDatasetType } from '../types';
import { OVERTIME_CHART_METRIC_LABEL, shadowPlugin } from '../constants';
import { ChartLegends } from './ChartLegends';

export const OvertimeChart = () => {
    const { metrics, timeseriesAnalysisData, timeseriesAnalysisIsLoading } =
        useProductAnalysisContext();
    const timeseriesAnalysisDataForOvertimeChart = getOvertimeChartData(
        timeseriesAnalysisData,
        metrics,
    );

    const { chartData } = useOvertimeChartData(timeseriesAnalysisDataForOvertimeChart);

    return (
        <StyledChartContainer
            title={<div className="text-xs font-medium text-navy">Overtime</div>}
            className="!h-[59vh] !w-full"
            chartClassName="!h-[49vh]"
            loading={timeseriesAnalysisIsLoading}
            loaderClassName="!w-full !h-[350px]"
        >
            <>
                <LineChart
                    data={chartData}
                    tooltip={{
                        callbacks: {
                            labelColor(this, tooltipItem) {
                                const dataset = tooltipItem.dataset as OvertimeChartDatasetType;
                                return {
                                    backgroundColor: dataset.tooltipBackgroundColor,
                                    borderColor: dataset.borderColor,
                                    borderDash: dataset.borderDash,
                                    borderDashOffset: dataset.borderDashOffset,
                                    borderWidth: 4,
                                    borderRadius: 1,
                                } as TooltipLabelStyle;
                            },
                        },
                    }}
                    legend={{ display: false }}
                    extraYAxis={{
                        y: {
                            type: 'linear',
                            position: 'left',
                            display: true,
                            title: {
                                display: metrics.includes(metrics[0]),
                                text: OVERTIME_CHART_METRIC_LABEL[metrics[0]],
                            },
                        },
                        'y-axis-2': {
                            type: 'linear',
                            position: 'right',
                            display: metrics.includes(metrics[1]),
                            title: {
                                display: true,
                                text: OVERTIME_CHART_METRIC_LABEL[metrics[1]],
                            },
                        },
                    }}
                    xAxis={{
                        grid: { display: false },
                        border: {
                            dash: [3, 3],
                        },
                    }}
                    yAxis={{
                        grid: { display: false },
                        border: {
                            dash: [3, 3],
                        },
                    }}
                    pluginsArray={[shadowPlugin]}
                />
                <ChartLegends
                    datasets={chartData.datasets}
                    chartMetricLabels={OVERTIME_CHART_METRIC_LABEL}
                />
            </>
        </StyledChartContainer>
    );
};
