import { useMutation } from '@tanstack/react-query';
import ndjsonStream from 'can-ndjson-stream';

export const useHermesChat = ({ disabled }: { disabled?: boolean } = {}) => {
    const sendChat = async ({ message, setResponse, setInProgress }) => {
        if (!disabled) return;
        const url = new URL(`https://pandora-api.internal.arena-ai.com/v2/search?q=`);
        setInProgress(true);

        const msg = message.text_input[0].split(' ').join('+');

        try {
            const response = await fetch(url.href + msg, {
                method: 'GET',
                headers: {
                    accept: 'application/json',
                    Authorization: `Basic YWRtaW46QzBjMG51dE9yUGluZUBwcGwz`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to send message.');
            }
            const ndjson = ndjsonStream(response.body);
            const reader = ndjson.getReader();
            /* eslint-disable no-await-in-loop */
            /* eslint-disable no-promise-executor-return */
            while (true) {
                const resp = await reader.read();
                if (resp.done) {
                    break;
                } else {
                    setResponse(resp.value);
                    await new Promise(resolve => setTimeout(resolve, 150));
                }
            }
            setInProgress(false);
        } catch (error) {
            setInProgress(false);
            throw new Error(`An error occurred while making the post request: ${error.stack}`);
        }
    };
    const { mutate, error, isPending } = useMutation({ mutationFn: sendChat });
    return {
        chatLoading: isPending,
        chatError: error as Error,
        sendChat: mutate,
    };
};
