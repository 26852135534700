import ResponsiveLayout from '@/components/responsive/ResponsiveLayout';
import { useNavItems } from '@/components/navBar/useNavItems';
import { useAppContext } from '@/contexts/appContext';
import Search from './search';
import HermesHome from './hermesHome';
import { useUserAccess } from '../hooks/useUserAccess';
import ConsolePage from './console';
import InspectPage from './insights';
import AllExperiments from './experiments';
import PersonalizationsPage from './personalizations';
import ConfigurationPage from './configurations';
import Hermes from './hermes';
import Ontology from './ontology';
import Phobos from './pricing';
import StoresPage from './stores';
import MarketPerformance from './market-performance';
import ProductAnalysis from './product-analysis';
import PromosPage from './promos';
import Catalog from './catalog';
import InsomniaDashboard from './insomnia-dashboard';

const componentsStack = {
    console: <ConsolePage />,
    insights: <InspectPage />,
    experiments: <AllExperiments />,
    personalizations: <PersonalizationsPage />,
    configurations: <ConfigurationPage />,
    search: <Search />,
    hermes: <Hermes />,
    ontology: <Ontology />,
    pricing: <Phobos />,
    stores: <StoresPage />,
    'market-performance': <MarketPerformance />,
    'product-analysis': <ProductAnalysis />,
    promos: <PromosPage />,
    catalog: <Catalog />,
};
const Home = () => {
    const { user } = useAppContext();
    const isBauschStaff = useUserAccess({ userGroupWhitelist: ['bausch-users'] });
    const isDemoUser = useUserAccess({ userGroupWhitelist: ['demo-users'] });
    const isInsomniaUser = useUserAccess({ userGroupWhitelist: ['insomnia-users'] });
    const navItems = useNavItems();

    const homePageName = (
        user?.groups?.includes('arena-staff')
            ? navItems
            : navItems.filter(item => item.userGroupWhitelist.some(x => user?.groups?.includes(x)))
    )?.[0]?.href;

    if (isBauschStaff) {
        return <Search />;
    }

    if (isInsomniaUser) {
        return <InsomniaDashboard />;
    }

    if (isDemoUser) {
        return <HermesHome />;
    }
    if (homePageName in componentsStack) return componentsStack[homePageName];
    return <ResponsiveLayout title="homePage" />;
};

export default Home;
