import React from 'react';
import _ from 'lodash';
import { Spinner } from 'andromeda';
import InsightCard from '../InsightCard';
import { useAlertsByQueryParams } from '../useAlertsByQueryParams';
import { AlertItem } from './useAlerts';
import { useProductSearchQueryParams } from './useProductSearchQueryParams';

const DUMMY_ALERT_FOR_NO_DATA: AlertItem = {
    state: 'CA',
    name: 'Jose Cuervo Especial Silver 750mL',
    title: 'Raise PTC by $4.00',
    analyze_messages: [
        {
            type: '',
            message: 'PTC significantly below MSRP',
        },
        {
            type: '',
            message: 'Competitor prices have increased',
        },
    ],
    alert: false,
    banner: '',
    brand: '',
    confidence: '',
    id: '',
    message: '',
    noted: false,
    size: '',
    UPC: '',
    alert_feedback: [],
    created_at: '',
    score: 0,
    type: '',
};

export const MarketInsights = () => {
    const { brand } = useProductSearchQueryParams();
    const { data, isFetching } = useAlertsByQueryParams();

    if (isFetching) {
        return (
            <div className="mt-16">
                <Spinner />
            </div>
        );
    }

    if (!data?.length) {
        return (
            <div className="relative mt-8 w-fit">
                <InsightCard
                    item={DUMMY_ALERT_FOR_NO_DATA}
                    className="!pointer-events-none w-fit !rounded opacity-20"
                />
                <div className="absolute inset-0 z-10 flex items-center justify-center rounded bg-secondary-30 p-3 text-sm text-primary-90">
                    No active insights or alerts{brand ? ' for this brand' : ''}.
                </div>
            </div>
        );
    }

    return (
        <div className="w-full overflow-x-auto pb-3">
            <div className="mt-8 grid grid-cols-4 gap-4">
                {_.take(data, 4).map(item => (
                    <InsightCard item={item} key={item.UPC} />
                ))}
            </div>
        </div>
    );
};
