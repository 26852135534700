/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC } from 'react';
import { Spinner } from 'andromeda';
import { useMarketIntelligenceDiveData } from '../hooks/useMarketIntelligenceDiveData';
import { Card } from './Card';

interface SelectedCardProps {
    onClick?: () => void;
    cardValue: string;
    cardCategory: string;
    path: [string, string][];
    timescale: string;
}
export const SelectedCard: FC<SelectedCardProps> = ({
    onClick,
    cardValue,
    cardCategory,
    path,
    timescale,
}) => {
    const { data, isLoading } = useMarketIntelligenceDiveData({
        body: {
            path,
            timescale,
        },
    });

    if (!data) return null;

    if (isLoading) return <Spinner size={24} />;

    const cardData = data?.find(item => item.value === cardValue);
    const cardsData = data?.filter(item => item.level === cardCategory);

    return <Card cardData={cardData} cardsData={cardsData} onClick={onClick} />;
};
