import { useState } from 'react';
import { useRouter } from 'next/router';
import { Text } from '@mantine/core';
import { ChatsCircle, LineSegment } from 'phosphor-react';
import ResponsiveLayout from '@/components/responsive/ResponsiveLayout';
import { HermesHomeFullPageChat } from '@/components/pipeline/HermesHomeFullPageChat';
import OrbyLoader from '../components/utils/orbyLoader';
import { Button } from '../andromeda/Button';
import useColorScheme from '../hooks/useColorScheme';

const BaseCircle = ({ size, children }) => {
    const { colorScheme: theme } = useColorScheme();
    return (
        <div
            className="flex h-screen items-center justify-center"
            style={{
                width: `${size}px`,
                height: `${size}px`,
                flexShrink: 0,
                borderRadius: `${size}px`,
                border: `1px solid ${
                    theme === 'light' ? 'rgba(183, 183, 183, 0.10)' : 'rgba(67, 67, 67, 0.10)'
                }`,
                background: `linear-gradient(135deg, ${
                    theme === 'light'
                        ? 'rgba(230, 236, 242, 0.30) 0%, rgba(240, 234, 243, 0.30) 100%'
                        : 'rgba(37, 38, 38, 0.30) 0%, rgba(47, 46, 48, 0.30) 100%'
                })`,
                boxShadow: `8px 8px 16px 0px ${
                    theme === 'light' ? 'rgba(10, 12, 15, 0.05)' : 'rgba(240, 238, 235, 0.05)'
                }`,
                backdropFilter: `blur(8px)`,
            }}
        >
            {children}
        </div>
    );
};

const HermesHome = () => {
    const [visible, setVisible] = useState(false);
    const router = useRouter();

    return (
        <ResponsiveLayout title="Hermes" className="!p-0">
            <div className="flex h-screen items-center justify-center overflow-hidden">
                <BaseCircle size={1358}>
                    <BaseCircle size={1182}>
                        <BaseCircle size={1016}>
                            <BaseCircle size={844}>
                                <BaseCircle size={672}>
                                    <BaseCircle size={500}>
                                        <BaseCircle size={328}>
                                            <div className="flex flex-col items-center gap-8">
                                                <OrbyLoader
                                                    size={48}
                                                    className="size-10 justify-center"
                                                />
                                                <Text
                                                    style={{
                                                        color: `var(--navy-solid-70, #515674)`,
                                                        fontSize: '28px',
                                                        fontWeight: 500,
                                                        lineHeight: 'normal',
                                                    }}
                                                >
                                                    Welcome to Hermes
                                                </Text>
                                                <Text
                                                    className="w-[500px]"
                                                    style={{
                                                        color: `var(--navy-solid-50, #81859B)`,
                                                        fontSize: '14px',
                                                        textAlign: 'center',
                                                        fontWeight: 500,
                                                        lineHeight: 'normal',
                                                    }}
                                                >
                                                    Here you can view the status of the data’s
                                                    progression through ingestion and enhancement,
                                                    resolve issues that may arise, and consult
                                                    OneBrain for insights into your data. To begin,
                                                    you can view the pipeline or query OneBrain’s
                                                    extensive knowledge of CPG.
                                                </Text>
                                                <div className="flex flex-row gap-4">
                                                    <Button
                                                        className="h-[30px]"
                                                        leftSection={<LineSegment size={16} />}
                                                        onClick={() => router.push('/hermes')}
                                                    >
                                                        View Data
                                                    </Button>
                                                    <Button
                                                        className="h-[30px]"
                                                        leftSection={<ChatsCircle size={16} />}
                                                        onClick={() => setVisible(true)}
                                                        disabled
                                                        inactive
                                                        disabledTooltipLabel="Coming soon."
                                                    >
                                                        Ask Hermes
                                                    </Button>
                                                </div>
                                            </div>
                                        </BaseCircle>
                                    </BaseCircle>
                                </BaseCircle>
                            </BaseCircle>
                        </BaseCircle>
                    </BaseCircle>
                </BaseCircle>
            </div>
            <HermesHomeFullPageChat visible={visible} setVisible={setVisible} />
        </ResponsiveLayout>
    );
};

export default HermesHome;
