/* eslint-disable @typescript-eslint/naming-convention */
import { MarketIntelligenceDive } from '../types';
import { getCardBgColor, getFormattedCardValues } from '../utils';

interface CardDataProps {
    cardData: MarketIntelligenceDive;
    cardsData: MarketIntelligenceDive[];
}
export const useCardData = (props: CardDataProps) => {
    const { cardData, cardsData } = props;
    const { dol_cya, value } = cardData;
    const { formattedDol, formattedDolCya, formattedDolPctChange, formattedShareOfRoot } =
        getFormattedCardValues(cardData);

    const isCardPositive = dol_cya >= 0;
    const cardBgColor = getCardBgColor(isCardPositive, dol_cya, cardsData);

    return {
        cardBgColor,
        formattedDol,
        formattedDolCya,
        formattedDolPctChange,
        formattedShareOfRoot,
        value,
        isCardPositive,
    };
};
