/* eslint-disable @typescript-eslint/naming-convention */
import { divergentScales } from '@/constants/colors';
import Color from 'color';
import { formatNumber } from '@/utils/numberFormatHelpers';
import { MarketIntelligenceDive } from './types';
import { CARD_GAP_WIDTH, CARD_WIDTH } from './constants';

export function scale(
    number: number,
    inMin: number,
    inMax: number,
    outMin: number,
    outMax: number,
) {
    return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

export const getCardBgColor = (
    isCardPositive: boolean,
    dol_cya: number,
    cardsData: MarketIntelligenceDive[] = [],
) => {
    const maxPositiveDolCyaValue = Math.max(
        ...cardsData.filter(item => item.dol_cya >= 0).map(item => item.dol_cya),
    );
    const minNegativeDolCyaValue = Math.min(
        ...cardsData.filter(item => item.dol_cya < 0).map(item => item.dol_cya),
    );

    const opacity = +scale(
        dol_cya,
        0,
        isCardPositive ? maxPositiveDolCyaValue : minNegativeDolCyaValue,
        0.7,
        1,
    ).toFixed(2);

    const cardBgColor = Color(
        isCardPositive ? divergentScales.positive[100] : divergentScales.negative[100],
    )
        .alpha(opacity)
        .hexa();

    return cardBgColor;
};

export const getCardTooltipLabels = (
    type: 'dol_cya' | 'revenue_usd' | 'share_of_root',
    cardData: MarketIntelligenceDive,
) => {
    const { dol_cya, dol_pct_change, dol } = cardData || {};
    if (type === 'dol_cya') {
        return `Dollars change (YA) ${formatNumber(
            dol_cya,
            '$0,0.00',
        )} Dollars percent change (YA) ${formatNumber(dol_pct_change, '0.00%')}`;
    }
    if (type === 'revenue_usd') {
        return `Revenue (USD) ${formatNumber(dol, '$0,0.00')}`;
    }
    if (type === 'share_of_root') {
        return '% of Dollars (CYA) US';
    }
    return '';
};

export const getFormattedCardValues = ({
    dol_cya,
    dol_pct_change,
    dol,
    share_of_root,
}: MarketIntelligenceDive) => {
    const formattedDolCya = formatNumber(dol_cya, '0.0a').toUpperCase();
    const formattedDolPctChange = formatNumber(dol_pct_change, '0.0%');
    const formattedDol = formatNumber(dol, '0.0a').toUpperCase();
    const formattedShareOfRoot = formatNumber(share_of_root, '0.0%');

    return {
        formattedDolCya,
        formattedDolPctChange,
        formattedDol,
        formattedShareOfRoot,
    };
};

export const getSortedCards = (allCardsData: MarketIntelligenceDive[] = []) =>
    allCardsData.sort((a, b) => b.dol_cya - a.dol_cya);

export const calculateVisibleCards = (containerWidth: number) => {
    const cardWidth = CARD_WIDTH; // Fixed width of a single card
    const gapWidth = CARD_GAP_WIDTH; // Gap between cards
    const availableWidth = containerWidth;

    // Calculate number of cards that can fit in the available width
    const cardsThatCanFit = Math.floor((availableWidth + gapWidth) / (cardWidth + gapWidth));
    return cardsThatCanFit;
};

export const getCategoryAndValue = (path: string) => ({
    category: path.split(' - ')[0],
    value: path.split(' - ')[1],
});

export const getSelectedCardPath = (index: number, selectedPath: string[]): [string, string][] =>
    selectedPath
        .slice(0, index)
        .map(item => [getCategoryAndValue(item).category, getCategoryAndValue(item).value]);
