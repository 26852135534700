import { useMemo } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { getLastNMonths, getProductNameWithSizeFromProductList, sumValuesByMonth } from '../utils';
import { useProductAnalysisContext } from '../context/ProductAnalysisContextProvider';
import { TimeseriesAnalysisDataResponse } from '../types';
import { CHART_COLORS, OVERTIME_CHART_METRIC_LABEL } from '../constants';

export const useOvertimeChartData = (timeseriesAnalysisData: TimeseriesAnalysisDataResponse) => {
    const { timeframe, productList, selectedProducts, competitors, metrics } =
        useProductAnalysisContext();
    const labels = getLastNMonths(+timeframe);

    const datasets = useMemo(() => {
        const allDatasets = _.chain(selectedProducts)
            .map((product, idx) => {
                const productData = timeseriesAnalysisData?.[product];
                return _.map(productData, (data, key) => {
                    const dates = data.time;
                    const values = data.value;
                    const result = sumValuesByMonth(dates, values);
                    const metricLabel = OVERTIME_CHART_METRIC_LABEL[key] ?? 'Unknown';
                    return {
                        label: `${metricLabel} - ${getProductNameWithSizeFromProductList(productList, competitors, product)}`,
                        data: labels.map(month => result[month] || 0),
                        borderWidth: 1,
                        yAxisID: metrics[0] === key ? 'y' : 'y-axis-2',
                        borderColor: CHART_COLORS[idx],
                        backgroundColor: CHART_COLORS[idx],
                        tooltipBackgroundColor: CHART_COLORS[idx],
                        borderDash: metrics[0] === key ? [] : [5, 5],
                        legendDash: metrics[0] === key ? 'outline' : 'dashed',
                        shadowColor: CHART_COLORS[idx],
                    };
                });
            })
            .flatten()
            .value();

        return allDatasets;
    }, [labels, timeseriesAnalysisData, productList, selectedProducts, metrics, competitors]);

    const chartData = {
        labels: labels.map(item => item.slice(0, 3)),
        datasets,
    };

    return {
        chartData,
    };
};
