import useApiQuery from '@/hooks/useApiQuery';
import { PROMO_API_URL } from 'buildConfig/processEnv';
import { MarketIntelligenceDive } from '../types';

export const useMarketIntelligenceDiveData = ({
    body: { timescale, path = [] },
}: {
    body: { timescale: string; path?: [string, string][] };
}) =>
    useApiQuery<MarketIntelligenceDive[]>({
        url: `${PROMO_API_URL}/promo-management/api/promo-management/get_market_intelligence_dive`,
        method: 'POST',
        body: {
            timescale,
            path,
        },
    });
