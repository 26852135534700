import React from 'react';
import { Divider } from '@mantine/core';
import { TopSection } from './components/TopSection';
import { DeepDiveAndRegions } from './components/DeepDiveAndRegions';

const MarketPerformance = () => (
    <>
        <TopSection />
        <Divider className="!border-t-border-color" />
        <DeepDiveAndRegions />
    </>
);

export default MarketPerformance;
