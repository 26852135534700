import useApiQuery from '@/hooks/useApiQuery';
import { PROMO_API_URL } from 'buildConfig/processEnv';
import { CompetitorsDataResponse } from '../types';

export const useCompetitorsData = (body: { UPC: string }) => {
    const result = useApiQuery<CompetitorsDataResponse>({
        url: `${PROMO_API_URL}/promo-management/api/promo-management/get_similar_product`,
        method: 'POST',
        body,
    });

    return {
        ...result,
        data: result.data || [],
    };
};
