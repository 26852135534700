import { Title } from '@mantine/core';
import React from 'react';
import _ from 'lodash';
import { Spinner } from 'andromeda';
import { NoSearchContent } from './NoSearchContent';
import { PhobosCard } from '../PhobosCard';
import { useProductListByQueryParams } from '../useProductListByQueryParams';

export const ProductResults: React.FC = () => {
    const { data: productList, isFetching: productListLoading } = useProductListByQueryParams();

    if (productListLoading) {
        return (
            <div className="mt-16">
                <Spinner />
            </div>
        );
    }

    if (!productList?.length) {
        return <NoSearchContent>Begin by searching a brand to view a product</NoSearchContent>;
    }

    return (
        <div className="mt-8">
            <div className="w-full overflow-x-auto pb-4">
                <div className="grid min-w-[991px] auto-cols-fr grid-cols-6 gap-4">
                    {_.take(productList, 6).map(product => (
                        <PhobosCard key={product.UPC} product={product} />
                    ))}
                </div>
            </div>
        </div>
    );
};
