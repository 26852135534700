enum ProgressStatus {
    success = 'success',
    error = 'error',
    warning = 'warning',
    info = 'info',
}

type PipelineStatus = 'done' | 'in-progress' | 'todo';

export interface ProgressData {
    id: number;
    dataSource?: string;
    dataType?: string;
    dataProgress?: { val: number; type: string };
    dataError?: string;
    dataUrl?: string;
    state?: string;
    source?: number;
    target?: number;
    startDate?: string;
}

export interface PipelineData {
    title: string;
    description: string;
    status: PipelineStatus;
    state: string;
    data: ProgressData[];
}

const progressMap = val => {
    if (val <= 25) {
        return ProgressStatus.error;
    }
    if (val <= 50) {
        return ProgressStatus.warning;
    }
    if (val <= 75) {
        return ProgressStatus.info;
    }
    return ProgressStatus.success;
};

export const pipelineData: PipelineData[] = [
    {
        title: 'Connect',
        description:
            '8 datasets and 38,765 rows of data have been successfully connected. 3 datasets require your attention.',
        status: 'done',
        state: '5/6',
        data: [
            {
                id: 1.1,
                dataSource: 'Oracle',
                dataType: 'Sell-in data',
                dataProgress: { val: 100, type: progressMap(100) },
                dataError: '',
                dataUrl: '',
                state: 'active',
                startDate: '2020-01-01',
                source: 1.1,
                target: 2.1,
            },
            {
                id: 1.2,
                dataSource: 'SnowFlake',
                dataType: 'Product Master Data',
                dataProgress: { val: 70, type: progressMap(70) },
                dataError: '',
                dataUrl: '',
                state: 'active',
                startDate: '2020-01-01',
                source: 1.1,
                target: 2.1,
            },
            {
                id: 1.3,
                dataSource: 'Databricks',
                dataType: 'Geolocation Data',
                dataProgress: { val: 40, type: progressMap(40) },
                dataError: 'Server Error',
                dataUrl: 'https://www.databricks.com',
                state: 'active',
                startDate: '2020-01-01',
                source: 1.1,
                target: 2.1,
            },
            {
                id: 1.4,
                dataSource: 'Facebook',
                dataType: 'Social Media',
                dataProgress: { val: 10, type: progressMap(10) },
                dataError: 'Some other error',
                dataUrl: 'https://www.facebook.com',
                state: 'active',
                startDate: '2020-01-01',
                source: 1.1,
                target: 2.1,
            },
        ],
    },
    {
        title: 'Clean',
        description: '8 data sets are being processed and 32560 rows have completing processing.',
        status: 'todo',
        state: '0/6',
        data: [
            {
                id: 3.1,
                dataSource: 'SAP',
                dataType: 'Sell-in data',
                dataProgress: { val: 100, type: progressMap(100) },
                dataError: '',
                dataUrl: '',
                state: 'active',
                startDate: '2020-01-01',
                source: 1.1,
                target: 2.1,
            },
            {
                id: 3.2,
                dataSource: 'SnowFlake',
                dataType: 'Product Master Data',
                dataProgress: { val: 70, type: progressMap(70) },
                dataError: '',
                dataUrl: '',
                state: 'active',
                startDate: '2020-01-01',
                source: 1.1,
                target: 2.1,
            },
            {
                id: 3.3,
                dataSource: 'Databricks',
                dataType: 'Geolocation Data',
                dataProgress: { val: 40, type: progressMap(40) },

                state: 'active',
                startDate: '2020-01-01',
                source: 1.1,
                target: 2.1,
            },
            {
                id: 3.4,
                dataSource: 'Facebook',
                dataType: 'Social Media',
                dataProgress: { val: 10, type: progressMap(10) },

                state: 'active',
                startDate: '2020-01-01',
                source: 1.1,
                target: 2.1,
            },
        ],
    },
    {
        title: 'ER & Merge',
        description: '8 data sets are being processed and 32560 rows have completing processing.',
        status: 'todo',
        state: '0/1',
        data: [
            {
                id: 4.1,
                dataSource: 'SAP',
                dataType: 'Sell-in data',
                dataProgress: { val: 100, type: progressMap(100) },
                dataError: '',
                dataUrl: '',
                state: 'active',
                startDate: '2020-01-01',
                source: 1.1,
                target: 2.1,
            },
        ],
    },
    {
        title: 'Enrich',
        description: '8 data sets are being processed and 32560 rows have completing processing.',
        status: 'todo',
        state: '0/4',
        data: [],
    },
];

export interface IngestionData {
    dataSource: string;
    dataType: string;
    details: {
        status: { val: number; type: ProgressStatus };
        startDate: string;
        elapsedTime: string;
    };
    actionLog: {
        id: number;
        action: string;
        date: string;
    }[];
}

export const ingestionData: IngestionData = {
    dataSource: 'Snowflake',
    dataType: 'Segment Data',
    details: {
        status: { val: 40, type: ProgressStatus.info },
        startDate: '2020-01-01',
        elapsedTime: '2h 30m',
    },
    actionLog: [
        {
            id: 1,
            action: 'Data connection has stalled because of an issue with the data provider, Snowflake.',
            date: '2020-01-01',
        },
        {
            id: 2,
            action: 'Segment data has been connected and is beginning process of upload to be processed.',
            date: '2020-01-01',
        },
    ],
};

export const fullPageChatSuggestions = brand => {
    if (brand === 'nestle') {
        return [
            'What are the top Nestle cereal products?',
            'Which neighborhood saw the least sales?',
            'What are the top 5 hottest neighborhoods?',
        ];
    }
    return [
        'Which 5 neighborhoods sold the most premium alcohol?',
        'Which neighborhood saw the most sales?',
        'What are the top 5 hottest neighborhoods?',
    ];
};

export const IngestionInsights = [
    {
        id: 1,
        title: 'Purchase Likelihood',
        status: 'complete',
    },
    {
        id: 1,
        title: 'Price sensitivity',
        status: 'complete',
    },
    {
        id: 1,
        title: 'Purchased volume',
        status: 'complete',
    },
    {
        id: 1,
        title: 'Demographic behavior',
        status: 'complete',
    },
    {
        id: 1,
        title: 'Seasonal/holiday impact',
        status: 'todo',
    },
];

export const ApplicationData = [];
