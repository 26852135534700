import ResponsiveLayout from '@/components/responsive/ResponsiveLayout';
import { PROXIMO_USER_GROUP_WHITELIST } from '@/components/navBar/constants';
import { UserGuard } from '@/components/utils/UserGuard';
import { ProductSearch } from '../../components/phobos/ProductSearch';

const Phobos = () => (
    <ResponsiveLayout title="Phobos" className="!p-0">
        <UserGuard userGroupWhitelist={PROXIMO_USER_GROUP_WHITELIST}>
            <ProductSearch />
        </UserGuard>
    </ResponsiveLayout>
);

export default Phobos;
