import { Title } from '@mantine/core';
import Select from 'andromeda/selects/select';
import { STATE_NAME_MAP } from '@/constants/siberia/commercial-impact';
import { useBrandSize } from '../../../hooks/useBrandSize';
import { useProductSearchQueryParams } from './useProductSearchQueryParams';
import { ProductHome } from './ProductHome';

export const ProductSearch = () => {
    const { brand, size, state, updateBrand, updateSize, updateState } =
        useProductSearchQueryParams();

    const { data: brandSizeData, isFetching: brandSizeDataLoading } = useBrandSize();

    return (
        <div className="h-full p-10 !pt-6">
            <Title order={2} className="!mb-4 !basis-1/12 !text-navy-solid-50">
                Pricing Intelligence
            </Title>
            <div className="flex max-w-xl items-center gap-1">
                <Select
                    key={brand} // for clearing input after deselect
                    placeholder="Brand"
                    data={brandSizeData?.brand_names ?? []}
                    clearable
                    value={brand}
                    onChange={updateBrand}
                    width={228}
                    loading={brandSizeDataLoading}
                />
                <Select
                    key={size} // for clearing input after deselect
                    placeholder="Size"
                    data={brandSizeData?.sizes ?? []}
                    clearable
                    value={size}
                    width={140}
                    onChange={updateSize}
                    loading={brandSizeDataLoading}
                />
                <Select
                    key={state}
                    placeholder="State"
                    data={Object.keys(STATE_NAME_MAP)}
                    clearable
                    value={state}
                    width={140}
                    onChange={updateState}
                />
            </div>
            <ProductHome />
        </div>
    );
};
