/* eslint-disable @next/next/no-img-element */
import React from 'react';
import styled from '@emotion/styled';
import { ArrowRight } from 'phosphor-react';
import { useRouter } from 'next/router';
import useColorScheme from '@/hooks/useColorScheme';
import { Tooltip } from '@mantine/core';
import { PhobosProduct } from '../../types/PhobosProduct';
import { useProductSearchQueryParams } from './ProductSearch/useProductSearchQueryParams';

export const StyledPhobosCard = styled.div`
    box-shadow: ${({ theme }: any) =>
        theme === 'light'
            ? '4px 4px 8px 0px rgba(10, 12, 15, 0.05)'
            : '4px 4px 8px 0px rgba(245, 243, 240, 0.05)'};

    :hover {
        box-shadow: ${({ theme }: any) =>
            theme === 'light'
                ? '4px 4px 8px 0px rgba(0, 0, 0, 0.15)'
                : '4px 4px 8px 0px rgba(255, 255, 255, 0.15)'};
    }
`;

interface PhobosCardProps {
    product: PhobosProduct;
}

export const PhobosCard: React.FC<PhobosCardProps> = ({ product }) => {
    const router = useRouter();
    const { colorScheme } = useColorScheme();
    const { state } = useProductSearchQueryParams();
    const imageUrl = (() => {
        if (product?.image.startsWith('http')) {
            return product.image;
        }
        return '/images/missingImage.png';
    })();

    const onClick = () => {
        router.push(
            `/pricing/${encodeURIComponent(product.UPC)}?screen=product-details${state ? `&selectedState=${state}` : ''}`,
        );
    };

    return (
        <StyledPhobosCard
            className="gradient-bg cursor-pointer rounded border-l border-t border-border-color p-2 !pb-3 transition"
            onClick={onClick}
            theme={colorScheme}
        >
            <img
                src={imageUrl}
                alt={product.UPC}
                className="h-36 w-full rounded bg-white object-contain"
            />
            <div>
                <div className="mt-4 text-xxs capitalize text-primaryBlue">{product.category}</div>
                <Tooltip label={product.name} disabled={product.name.length < 37}>
                    <div className="truncate text-xs font-medium text-primary-70">
                        {product.name}
                    </div>
                </Tooltip>
                <div className="mt-4 flex items-center gap-1 text-xxs text-primary-30">
                    <div className="truncate">UPC: {product.UPC}</div>|
                    <div className="whitespace-nowrap">Size: {product.size}</div>
                </div>
                <hr className="my-3" />
                <button
                    type="button"
                    className="flex items-center gap-2 text-xxs text-primary-50"
                    onClick={onClick}
                >
                    View pricing <ArrowRight className="text-primaryBlue" />
                </button>
            </div>
        </StyledPhobosCard>
    );
};
