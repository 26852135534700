/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC } from 'react';
import { ChartPieSlice } from 'phosphor-react';
import StyledTooltip from '@/components/utils/styledTooltip';
import cx from 'classnames';
import useColorScheme from '@/hooks/useColorScheme';
import { MarketIntelligenceDive } from '../types';
import { getCardTooltipLabels } from '../utils';
import { CARD_WIDTH } from '../constants';
import { useCardData } from '../hooks/useCardData';

interface CardProps {
    cardData: MarketIntelligenceDive;
    cardsData: MarketIntelligenceDive[];
    onClick?: () => void;
}

const TOOLTIP_BG = {
    light: '!bg-navy',
    dark: undefined,
};

export const Card: FC<CardProps> = ({ cardData, cardsData, onClick }) => {
    const {
        cardBgColor,
        value,
        isCardPositive,
        formattedDol,
        formattedDolCya,
        formattedDolPctChange,
        formattedShareOfRoot,
    } = useCardData({ cardData, cardsData });
    const { colorScheme } = useColorScheme();
    return (
        <button type="button" onClick={onClick} className="group">
            <div
                style={{
                    backgroundColor: cardBgColor,
                    width: CARD_WIDTH,
                }}
                className={cx(
                    'relative h-[137px] text-white flex flex-col gap-[2px] items-center justify-center rounded-md transition duration-300',
                    {
                        'cursor-default': !onClick,
                    },
                )}
            >
                <div className="absolute inset-0 z-10 bg-transparent transition-opacity duration-300 group-hover:bg-white-20" />
                <StyledTooltip
                    className="font-light"
                    label={value}
                    classNames={{
                        tooltip: TOOLTIP_BG[colorScheme],
                    }}
                >
                    <div className="z-20 max-w-[115px] overflow-hidden truncate  text-sm font-bold text-white">
                        {value}
                    </div>
                </StyledTooltip>
                <div
                    className={cx('rounded-2xl  px-2 py-1 flex items-center gap-1', {
                        'bg-[#3253E1]': isCardPositive,
                        'bg-[#C52237]': !isCardPositive,
                    })}
                >
                    <StyledTooltip
                        multiline
                        w={280}
                        className="font-light"
                        label={getCardTooltipLabels('dol_cya', cardData)}
                        classNames={{
                            tooltip: TOOLTIP_BG[colorScheme],
                        }}
                    >
                        <div className="z-20 text-sm font-bold text-white">{formattedDolCya}</div>
                    </StyledTooltip>
                    <div className="text-sm font-bold text-white-80">{formattedDolPctChange}</div>
                </div>
                <StyledTooltip
                    className="font-light"
                    label={getCardTooltipLabels('revenue_usd', cardData)}
                    classNames={{
                        tooltip: TOOLTIP_BG[colorScheme],
                    }}
                >
                    <div className="z-20 text-sm font-bold text-white-60">{formattedDol}</div>
                </StyledTooltip>
                <StyledTooltip
                    label={getCardTooltipLabels('share_of_root', cardData)}
                    classNames={{
                        tooltip: TOOLTIP_BG[colorScheme],
                    }}
                >
                    <div className="flex items-center gap-1">
                        <ChartPieSlice size={16} />
                        <div className="z-20 text-sm font-bold text-white">
                            {formattedShareOfRoot}
                        </div>
                    </div>
                </StyledTooltip>
            </div>
        </button>
    );
};
