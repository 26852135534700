import React, { useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import Color from 'color';
import { useProductAnalysisContext } from '../context/ProductAnalysisContextProvider';
import { getLastNMonths, getProductNameWithSizeFromProductList, sumValuesByMonth } from '../utils';
import { CHART_COLORS, YEAROVERYEAR_CHART_METRIC_LABEL } from '../constants';
import { TimeseriesAnalysisDataResponse } from '../types';

export const useYearOverYearChartData = (
    timeseriesAnalysisData: TimeseriesAnalysisDataResponse,
) => {
    const { metrics, timeframe, productList, competitors, selectedProducts } =
        useProductAnalysisContext();
    const labels = getLastNMonths(+timeframe);

    const datasets = useMemo(() => {
        const allDatasets = _.chain(selectedProducts)
            .map((product, idx) => {
                const productData = timeseriesAnalysisData?.[product];
                return _.map(productData, (data, key) => {
                    const dates = data.time;
                    const values = data.value;
                    const result = sumValuesByMonth(dates, values);
                    const metricLabel = YEAROVERYEAR_CHART_METRIC_LABEL[key] ?? 'Unknown';
                    return {
                        label: `${metricLabel} - ${getProductNameWithSizeFromProductList(productList, competitors, product)}`,
                        data: labels.map(month => ((result[month] || 0) * 100).toFixed(1)),
                        color: CHART_COLORS[idx],
                        key,
                    };
                });
            })
            .flatten()
            .map(dataset => ({
                ...dataset,
                backgroundColor:
                    `${metrics[0]}_yoy` === dataset.key
                        ? dataset.color
                        : Color(dataset.color).lighten(0.6).toString(),
                tooltipBackgroundColor: dataset.color,
                legendDash: 'normal',
            }))
            .value();

        return allDatasets;
    }, [labels, timeseriesAnalysisData, productList, selectedProducts, metrics, competitors]);

    const chartData = {
        labels: labels.map(item => item.slice(0, 3)),
        datasets,
    };
    return {
        chartData,
    };
};
