import { STATE_NAME_MAP } from '@/constants/siberia/commercial-impact';

export const TIMEFRAME_OPTIONS = [
    {
        label: '3',
        value: '3',
    },
    {
        label: '6',
        value: '6',
    },
    {
        label: '12',
        value: '12',
    },
];

export const DEFAULT_PRODUCT_UPC = '00811538010832';

export const OVERTIME_CHART_METRIC_LABEL = {
    revenue: 'Revenue ($)',
    revenue_share: 'Revenue Share (%)',
    volume: 'Volume (9L)',
    volume_share: 'Volume Share (%)',
    price: 'Price ($)',
};

export const YEAROVERYEAR_CHART_METRIC_LABEL = {
    revenue_yoy: 'Revenue (% Difference)',
    revenue_share_yoy: 'Revenue Share (% Difference)',
    volume_yoy: 'Volume 9L (% Difference)',
    volume_share_yoy: 'Volume Share (% Difference)',
    price_yoy: 'Price (% Difference)',
};

export const CHART_COLORS = ['#F11B97', '#6938B9'];

export const SELECT_STYLE_VALUE = {
    borderRadius: '8px',
    borderTop: '0.5px solid rgba(0, 0, 0, 0.20)',
    borderLeft: '0.5px solid rgba(0, 0, 0, 0.20)',
    background: 'var(--card-gradient-hex)',
    boxShadow: '8px 8px 16px 0px rgba(10, 12, 15, 0.05)',
    backdropFilter: 'blur(16px)',
    WebkitBackdropFilter: 'blur(16px)',
};

// Custom plugin for drawing shadow on lines
export const shadowPlugin = {
    id: 'shadowPlugin',
    beforeDatasetDraw: (chart, args) => {
        const ctx = chart.ctx;
        const dataset = chart.data.datasets[args.index];
        if (dataset.shadowColor) {
            // Check if shadowColor is defined
            ctx.save(); // Save the current state
            ctx.shadowColor = dataset.shadowColor;
            ctx.shadowBlur = 10;
            ctx.shadowOffsetX = 3;
            ctx.shadowOffsetY = 3;
        }
    },
    afterDatasetDraw: (chart, args) => {
        const ctx = chart.ctx;
        ctx.restore(); // Restore to the initial state to remove shadow
    },
};

export const TOOLTIP_BG = {
    light: '!bg-navy',
    dark: undefined,
};

export const CONTROL_STATES = [
    'OH',
    'MI',
    'NC',
    'PA',
    'VA',
    'AL',
    'OR',
    'MS',
    'IA',
    'ID',
    'UT',
    'NH',
    'MT',
    'ME',
    'WY',
    'WV',
    'VT',
];

export const OPEN_STATES = [
    'AZ',
    'CA',
    'CO',
    'FL',
    'IL',
    'IN',
    'KY',
    'LA',
    'MA',
    'MN',
    'MO',
    'NE',
    'NJ',
    'NM',
    'NV',
    'NY',
    'TX',
    'WA',
    'WI',
];
