import React from 'react';
import { TopSection } from './components/TopSection';
import { ChartsSection } from './components/ChartsSection';

const ProductAnalysis = () => (
    <>
        <TopSection />
        <ChartsSection />
    </>
);

export default ProductAnalysis;
