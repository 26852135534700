import dynamic from 'next/dynamic';
import ResponsiveLayout from '@/components/responsive/ResponsiveLayout';
import { ONTOLOGY_USER_GROUP_WHITELIST } from '@/components/navBar/constants';
import { UserGuard } from '@/components/utils/UserGuard';

const OntologyViewer = dynamic(() => import('../../components/pipeline/ontologyViewer'), {
    ssr: false,
});

const Ontology = () => (
    <ResponsiveLayout title="Ontology viewer" className="h-screen !py-0 !pr-0">
        <UserGuard userGroupWhitelist={ONTOLOGY_USER_GROUP_WHITELIST}>
            <OntologyViewer />
        </UserGuard>
    </ResponsiveLayout>
);

export default Ontology;
