import useProductList from '@/hooks/usePhobosProductList';
import { useProductSearchQueryParams } from './ProductSearch/useProductSearchQueryParams';

export const useProductListByQueryParams = () => {
    const { brand, size } = useProductSearchQueryParams();

    return useProductList({
        query: brand,
        amount: size,
    });
};
