import { ToggleOption } from 'andromeda';
import {
    ArrowsInCardinal,
    ArrowsInLineVertical,
    BeerBottle,
    Books,
    Factory,
    MapPin,
    MapTrifold,
    Storefront,
} from 'phosphor-react';

export const METRIC_OPTIONS: ToggleOption[] = [
    {
        label: 'Revenue',
        value: 'revenue',
    },
    {
        label: 'Volume',
        value: 'volume',
        disabled: true,
    },
    {
        label: 'Price',
        value: 'price',
        disabled: true,
    },
];

export const TIMEFRAME_OPTIONS: ToggleOption[] = [
    {
        label: 'YTD',
        value: 'YTD',
    },
    {
        label: 'Last 12 months',
        value: '52',
    },
    {
        label: 'Last 3 months',
        value: '13',
    },
    {
        label: 'Last month',
        value: '4',
    },
];

export const TITLES_KEY_ORDERS = [
    'manufacturer',
    'division',
    'state',
    'category',
    'spirits_price_tier',
    'brand_family',
    'market',
];

export const ICONS_FOR_CATEGORY_TITLES = {
    US: <ArrowsInCardinal className="text-navy" size={16} />,
    manufacturer: <Factory className="text-navy" size={16} />,
    division: <MapTrifold className="text-navy" size={16} />,
    market: <Storefront className="text-navy" size={16} />,
    category: <Books className="text-navy" size={16} />,
    brand_family: <BeerBottle className="text-navy" size={16} />,
    state: <MapPin className="text-navy" size={16} />,
    spirits_price_tier: <ArrowsInLineVertical className="text-navy" size={16} />,
};

export const KEY_TO_CATEGORY_TITLE = {
    manufacturer: 'Manufacturers',
    category: 'Key categories',
    brand_family: 'Key brands',
    state: 'Key states',
    spirits_price_tier: 'Key tiers',
    division: 'Divisions',
    market: 'Key Markets',
};

export const CARD_WIDTH = 174;
export const CARD_GAP_WIDTH = 8;
