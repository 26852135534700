import { Toggle } from 'andromeda';
import React from 'react';
import { primaryColors } from '@/constants/colors';
import { METRIC_OPTIONS, TIMEFRAME_OPTIONS } from '../constants';
import { useMarketPerformanceContext } from '../context/MarketPerformanceContextProvider';

export const TopSection = () => {
    const { updateTimeframe, updateMetric, selectedTimeframe, selectedMetric } =
        useMarketPerformanceContext();

    return (
        <div className="px-11 pb-6 pt-12">
            <div className="text-[21px] font-normal text-navy-solid-50">Market Performance</div>
            <div className="text-sm font-normal text-navy-solid-50">
                Understand & explore how your products performed across the market.
            </div>
            <div className="mt-6 flex gap-8">
                <div className="flex flex-col">
                    <div className="text-xs font-medium text-navy">Metric:</div>
                    <div className="mt-2">
                        <Toggle
                            options={METRIC_OPTIONS}
                            defaultOption={selectedMetric ?? METRIC_OPTIONS[0].value}
                            selectedBgColor={primaryColors.blue}
                            selectedTextColor="#FFF"
                            onSelectionChange={updateMetric}
                        />
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="text-xs font-medium text-navy">Timeframe:</div>
                    <div className="mt-2">
                        <Toggle
                            options={TIMEFRAME_OPTIONS}
                            defaultOption={selectedTimeframe}
                            selectedBgColor={primaryColors.blue}
                            selectedTextColor="#FFF"
                            onSelectionChange={updateTimeframe}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
