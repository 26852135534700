import React from 'react';
import { DropdownsSection } from './DropdownsSection';
import { TogglesSection } from './TogglesSection';

export const TopSection = () => (
    <div className="px-11 pb-5">
        <div className="mt-12 text-[21px] font-normal text-navy-solid-50">Product Analysis</div>
        <DropdownsSection />
        <TogglesSection />
    </div>
);
