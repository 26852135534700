import useApiQuery from '@/hooks/useApiQuery';
import { PROMO_API_URL } from 'buildConfig/processEnv';
import { useMemo } from 'react';
import _ from 'lodash';
import { TimeseriesAnalysisDataResponse } from '../types';

export const useTimeseriesAnalysisData = (body: {
    UPCS: string[];
    state: string;
    metrics: string[];
    min_date: string;
}) => {
    const result = useApiQuery<TimeseriesAnalysisDataResponse>({
        url: `${PROMO_API_URL}/promo-management/api/promo-management/get_time_series_analysis`,
        method: 'POST',
        body,
    });

    return {
        ...result,
        data: result.data,
    };
};
