import React, { useState } from 'react';
import cx from 'classnames';
import { colorsScheme } from '@/constants/colors';
import StyledTooltip from '@/components/utils/styledTooltip';
import useColorScheme from '@/hooks/useColorScheme';
import { TOOLTIP_BG } from '../constants';

export type ToggleItem = {
    label: string;
    value: string;
    tooltipText?: string;
};

interface MultiToggleProps {
    items: ToggleItem[];
    defaultValue?: Array<string>;
    title?: string;
    variant?: 'primary' | 'secondary';
    selectedBgColor?: string;
    selectedTextColor?: string;
    textColor?: string;
    onToggleChange: (activeToggles: ToggleItem[]) => void;
}

export const MultiselectToggle: React.FC<MultiToggleProps> = ({
    items,
    defaultValue = [],
    title,
    variant = 'secondary',
    selectedBgColor = 'transparent',
    selectedTextColor = colorsScheme.light['navy-solid-70'],
    textColor = colorsScheme.light['navy-solid-30'],
    onToggleChange,
}) => {
    const { colorScheme } = useColorScheme();
    const initializeToggles = () => items.map(item => defaultValue.includes(item.value));
    const [toggles, setToggles] = useState<boolean[]>(initializeToggles);

    const handleToggle = (index: number) => {
        if (toggles.filter(t => t).length < 2 || toggles[index]) {
            const newToggles = [...toggles];
            newToggles[index] = !newToggles[index];
            setToggles(newToggles);
            onToggleChange(items.filter((_, idx) => newToggles[idx]));
        }
    };

    return (
        <div className="flex items-center gap-2">
            {title && <div className="text-xs font-medium text-navy-solid-70">{title}</div>}
            <div className="flex items-center gap-1">
                {items.map((item, index) => {
                    const isSelected = toggles[index];
                    const isDisabled = toggles.filter(t => t).length >= 2 && !isSelected;
                    return (
                        <StyledTooltip
                            key={item.value}
                            label={
                                isDisabled
                                    ? 'Only 2 metrics can be selected at a time'
                                    : item.tooltipText
                            }
                            disabled={isDisabled ? false : !item.tooltipText}
                            classNames={{
                                tooltip: TOOLTIP_BG[colorScheme],
                            }}
                        >
                            <button
                                type="button"
                                className={cx('rounded-full cursor-pointer font-medium', {
                                    'hover:!text-navy-70': !isSelected && !isDisabled,
                                    'text-xs p-2': variant === 'secondary',
                                    'text-lg p-3': variant === 'primary',
                                    '!text-navy-solid-15 !cursor-default': isDisabled,
                                })}
                                style={{
                                    backgroundColor: isSelected ? selectedBgColor : 'transparent',
                                    color: isSelected ? selectedTextColor : textColor,
                                }}
                                onClick={() => handleToggle(index)}
                                disabled={isDisabled}
                            >
                                {item.label}
                            </button>
                        </StyledTooltip>
                    );
                })}
            </div>
        </div>
    );
};
