import { PhobosProduct } from '@/types/PhobosProduct';
import _ from 'lodash';
import moment from 'moment';
import { SelectItem } from 'andromeda/types/select/common';
import { CompetitorsDataResponse, SelectedStatesType } from './types';
import { CONTROL_STATES, OPEN_STATES } from './constants';

export const getDropdownOptions = (data: (PhobosProduct | CompetitorsDataResponse)[]) => {
    if (!data) {
        return [];
    }
    return Object.values(
        data.reduce(
            (acc: Record<string, SelectItem>, productVal) => ({
                ...acc,
                [`${productVal['name']} ${productVal['size']}`]: {
                    label: `${productVal['name']} ${productVal['size']}`,
                    value: productVal['UPC'],
                },
            }),
            {},
        ),
    ).sort((a, b) => a.label.localeCompare(b.label));
};

export const getProductOptions = (productList: PhobosProduct[]) => {
    if (!productList) {
        return [];
    }
    return getDropdownOptions(productList);
};

export const getCompetitorOptions = (competitors: CompetitorsDataResponse) => {
    if (!competitors || !competitors.length) {
        return [];
    }

    return [
        {
            group: 'Competitors',
            items: getDropdownOptions(competitors),
        },
    ];
};

export const getProductNameWithSizeFromProductList = (
    productList: PhobosProduct[],
    competitors: CompetitorsDataResponse,
    upc: string,
) => {
    const foundProduct = [...productList, ...competitors]?.find(product => product.UPC === upc);
    return `${foundProduct?.name} ${foundProduct?.size}`;
};

export const getMinDateByTimeframe = (timeframe: string) => {
    // Subtract the specified number of months from the current date
    let date = moment().subtract(timeframe, 'months');
    // Adjust to the first day of the resulting month
    date = date.startOf('month');
    return date.format('YYYY-MM-DD');
};

export function getLastNMonths(n: number) {
    const months = [];
    const currentDate = moment().subtract(1, 'months'); // Start from the previous month

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < n; i++) {
        // Format as 'MMM-YYYY' to include both month and year
        months.push(currentDate.format('MMM-YYYY'));
        currentDate.subtract(1, 'months'); // Move to the previous month
    }

    return months.reverse(); // Reverse to start from the earliest month
}

export function getUniqueMonths(dates: string[] = []) {
    const months = dates.map(date => moment(date).format('MMM')); // Convert each date to 'MMM' format
    const uniqueMonths = [...new Set(months)]; // Use a Set to remove duplicates
    return uniqueMonths;
}

export function sumValuesByMonth(dates: string[] = [], values: number[] = []) {
    const monthSums = {};

    dates.forEach((date, index) => {
        // Format date as 'MMM-YYYY' (e.g., 'Jan-2024')
        const monthYear = moment(date).format('MMM-YYYY');

        if (monthSums[monthYear]) {
            monthSums[monthYear] += values[index]; // Add to existing month sum
        } else {
            monthSums[monthYear] = values[index]; // Initialize new month sum
        }
    });

    return monthSums;
}

export const findDatasetByLabel = (label: string, datasets: any) =>
    datasets.find((dataset: any) => dataset.label === label);

export const getOvertimeChartData = (data: any, selectedMetrics: string[]) =>
    _.mapValues(data, subObj => _.pickBy(subObj, (value, key) => selectedMetrics.includes(key)));

export const getYearOverYearChartData = (data: any) =>
    _.mapValues(data, subObj => _.pickBy(subObj, (value, key) => _.includes(key, 'yoy')));

export const getMultiSelectToggleMetricOptions = (metricInfo: string) => [
    {
        label: 'Revenue',
        value: 'revenue',
    },
    {
        label: 'Revenue Share',
        value: 'revenue_share',
    },
    {
        label: 'Volume',
        value: 'volume',
    },
    {
        label: 'Volume Share',
        value: 'volume_share',
    },
    {
        label: 'Price',
        value: 'price',
        tooltipText: metricInfo,
    },
];

export const getStateData = (selectedStates: SelectedStatesType) => {
    const { controlStates, openStates } = selectedStates;
    let states = [];
    if (controlStates && openStates) {
        states = [...CONTROL_STATES, ...OPEN_STATES];
    }
    if (controlStates && !openStates) {
        states = CONTROL_STATES;
    }
    if (openStates && !controlStates) {
        states = OPEN_STATES;
    }
    return states.sort((a, b) => a.localeCompare(b));
};
