import useApiQuery from '@/hooks/useApiQuery';
import { PhobosProduct } from '@/types/PhobosProduct';
import { PROMO_API_URL } from 'buildConfig/processEnv';

export function useProductList() {
    const result = useApiQuery<PhobosProduct[]>({
        url: `${PROMO_API_URL}/promo-management/api/promo-management/query_product`,
        method: 'POST',
        body: {},
    });

    return {
        ...result,
        data: result.data || [],
    };
}
