import React, { createContext, FunctionComponent, ReactNode, useContext, useMemo } from 'react';
import { useMarketIntelligenceDiveData } from '../hooks/useMarketIntelligenceDiveData';
import { MarketIntelligenceDive } from '../types';
import { useMarketPerformanceQueryParams } from '../hooks/useMarketPerformanceQueryParams';

interface MarketPerformanceInterface {
    selectedMetric: string;
    updateMetric: (value: string) => void;
    selectedTimeframe: string;
    updateTimeframe: (value: string) => void;
    selectedPath: string[];
    updatePath: (value: string[]) => void;
    marketIntelligenceDiveData: MarketIntelligenceDive[];
    marketIntelligenceDiveDataIsLoading: boolean;
}

const MarketPerformanceContext = createContext<MarketPerformanceInterface | null>(null);

interface MarketPerformanceProviderProps {
    children: ReactNode;
}

const MarketPerformanceProvider: FunctionComponent<MarketPerformanceProviderProps> = ({
    children,
}) => {
    const { queryParams, updateTimeframe, updateMetric, updatePath } =
        useMarketPerformanceQueryParams();
    const { timeframe, metric, path } = queryParams;

    const { data: marketIntelligenceDiveData, isLoading: marketIntelligenceDiveDataIsLoading } =
        useMarketIntelligenceDiveData({
            body: {
                timescale: timeframe,
                path: path.map(item => {
                    const [level, value] = item.split(' - ');
                    return [level, value];
                }),
            },
        });

    const value = useMemo(
        () => ({
            selectedMetric: metric,
            updateMetric,
            selectedTimeframe: timeframe,
            updateTimeframe,
            selectedPath: path,
            updatePath,
            marketIntelligenceDiveData,
            marketIntelligenceDiveDataIsLoading,
        }),
        [
            marketIntelligenceDiveData,
            marketIntelligenceDiveDataIsLoading,
            path,
            timeframe,
            metric,
            updateTimeframe,
            updateMetric,
            updatePath,
        ],
    );

    return (
        <MarketPerformanceContext.Provider value={value}>
            {children}
        </MarketPerformanceContext.Provider>
    );
};

const useMarketPerformanceContext = (): MarketPerformanceInterface => {
    const context = useContext(MarketPerformanceContext);
    if (!context) {
        throw new Error('useMarketPerformance must be used within a MarketPerformanceProvider');
    }
    return context;
};

export { MarketPerformanceProvider, useMarketPerformanceContext };
