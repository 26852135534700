import React, { FC, useState } from 'react';
import { CaretDown } from 'phosphor-react';
import cx from 'classnames';
import { Collapse } from '@mantine/core';
import { Card } from './Card';
import { useMarketPerformanceContext } from '../context/MarketPerformanceContextProvider';
import { MarketIntelligenceDive } from '../types';
import { CARD_GAP_WIDTH, ICONS_FOR_CATEGORY_TITLES, KEY_TO_CATEGORY_TITLE } from '../constants';
import { getSortedCards } from '../utils';
import { useCardsDisplay } from '../hooks/useCardsDisplay';

interface CardsDisplayProps {
    className?: string;
    id?: string;
    cards: MarketIntelligenceDive[];
    onCardClick: (cardData: MarketIntelligenceDive) => void;
    allCardsData: MarketIntelligenceDive[];
}

const CardsDisplay: FC<CardsDisplayProps> = ({
    id,
    className,
    cards,
    onCardClick,
    allCardsData,
}) => (
    <div
        id={id}
        style={{
            gap: CARD_GAP_WIDTH,
        }}
        className={cx('w-full flex flex-wrap', className)}
    >
        {cards.map(cardData => (
            <Card
                key={cardData.value}
                cardData={cardData}
                onClick={() => onCardClick(cardData)}
                cardsData={allCardsData}
            />
        ))}
    </div>
);

interface CardsContainerProps {
    title: string;
    allCardsData: MarketIntelligenceDive[];
}

const CardsContainer: FC<CardsContainerProps> = ({ allCardsData, title }) => {
    const { updatePath, selectedPath } = useMarketPerformanceContext();
    const [isViewAll, setViewAll] = useState(false);
    const [visibleCardsCountToShow, setVisibleCardsCountToShow] = useState<number>();
    useCardsDisplay(setVisibleCardsCountToShow);

    const sortedCards = getSortedCards(allCardsData);
    const visibleCards = sortedCards.slice(0, visibleCardsCountToShow);
    const collapsedCards = sortedCards.slice(visibleCardsCountToShow);
    const isViewAllButtonVisible = collapsedCards.length > 0;

    const handleCardOnClick = (cardData: MarketIntelligenceDive) =>
        updatePath([...selectedPath, `${cardData.level} - ${cardData.value}`]);

    return (
        <div>
            <div className="mt-4 flex items-center gap-1">
                {ICONS_FOR_CATEGORY_TITLES[title]}
                <div className="text-xs font-bold text-navy">{KEY_TO_CATEGORY_TITLE[title]}</div>
            </div>
            <CardsDisplay
                id="cards-display-container"
                className="mt-4"
                cards={visibleCards}
                onCardClick={handleCardOnClick}
                allCardsData={allCardsData}
            />
            <Collapse in={isViewAll}>
                <CardsDisplay
                    className="mt-2"
                    cards={collapsedCards}
                    onCardClick={handleCardOnClick}
                    allCardsData={allCardsData}
                />
            </Collapse>
            {isViewAllButtonVisible && (
                <div className="mt-4 flex justify-end">
                    <button
                        type="button"
                        className="ml-auto"
                        onClick={() => setViewAll(!isViewAll)}
                    >
                        <div className="flex items-center gap-1">
                            <div className="text-xs font-bold text-navy-solid-50">View All</div>
                            <CaretDown
                                className={cx(
                                    'text-primaryBlue transform transition-all duration-200',
                                    { 'rotate-180': isViewAll },
                                )}
                                size={14}
                            />
                        </div>
                    </button>
                </div>
            )}
        </div>
    );
};

export { CardsDisplay, CardsContainer };
