import { useEffect, useMemo, useState } from 'react';
import { InspectUseCase } from '@/hooks/useInspect';
import { NewSelectPossibleValues, NewTypeSelectData } from 'andromeda/types/select/newTypeOfSelect';
import StyledTab from 'andromeda/tab/tabComponent';
import useInspectNamespaceData, {
    PHNestleInspectParamsType,
} from '../../hooks/useInspectNamespaceData';
import useInspectNamespaceDataDescribeTable from '../../hooks/useInspectNamespaceDescribeTable';
import TabButton from '../utils/tabButton';
import Graph from './graph';
import Table from './table';
import DatePicker from '../../andromeda/DatePicker';
import MultiLevelSelect from '../../andromeda/multiSelect/multiLevelSelect';
import { fusionMoment } from '../../utils/dateHelpers';
import { InvoiceTableFilters } from './utils';
import useRouterQuery from '../../hooks/useRouterQuery';
import { UseCaseDropdown } from '../utils/UseCaseDropdown';
import { NamespaceDropdown } from '../utils/NamespaceDropdown';
import { SpaceportCommonHeader } from '../utils/SpaceportCommonHeader';

type Tabs = 'graph' | 'table';

export default function InspectComponent() {
    const { handleURLParamsChange, router } = useRouterQuery<
        'tabQ' | 'chartKey' | 'usecase' | 'namespace'
    >();

    const [tab, setTab] = useState<Tabs>('graph');
    const [filters, setFilters] = useState<
        Partial<Record<string | 'graphGroupBy', NewSelectPossibleValues>>
    >({});
    const [useCase, setUseCase] = useState<InspectUseCase>('QUICK_ORDER');
    const [namespace, setNamespace] = useState<string>();
    const [tableFilters, setTableFilters] = useState<
        Partial<Record<InvoiceTableFilters | 'tableGroupBy', NewSelectPossibleValues>>
    >({ tableGroupBy: { type: 'select', value: 'date' } });
    const [tableFiltersData, setTableFiltersData] = useState<NewTypeSelectData[]>([]);
    const [{ fromDate, toDate }, setDate] = useState({
        fromDate: fusionMoment().subtract(1, 'day').subtract(1, 'M').format('YYYY-MM-DD'),
        toDate: fusionMoment().subtract(1, 'day').format('YYYY-MM-DD'),
    });
    const { data, isLoading } = useInspectNamespaceDataDescribeTable({
        useCase,
        namespace,
    });

    useEffect(() => {
        if (!router.isReady) return;
        const {
            tabQ,
            usecase,
            namespace: namespaceQ,
        } = router.query as { tabQ: Tabs; usecase: string; namespace: string };
        if (typeof tabQ === 'string') {
            setTab(tabQ);
        }
        if (typeof usecase === 'string') {
            setUseCase(usecase as InspectUseCase);
        }
        if (typeof namespaceQ === 'string') {
            setNamespace(namespaceQ);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.isReady]);

    const { whereOptions } = useMemo(
        () => ({
            whereOptions: Object.entries(filters).reduce(
                (acc: PHNestleInspectParamsType['where_options'], item) =>
                    // ? cleaning groupBys
                    item[1].value.length > 0 && !/groupby/gi.test(item[0])
                        ? [
                              ...acc,
                              {
                                  column_name: item[0],
                                  values:
                                      typeof item[1].value === 'string'
                                          ? [item[1].value]
                                          : (Array.isArray(item[1].value) &&
                                                item[1].value.map(a => a.toString())) ||
                                            item[1].value,
                              },
                          ]
                        : acc,
                [],
            ),
        }),
        [filters],
    );

    const { data: table, isLoading: tableDataLoading } = useInspectNamespaceData({
        enabled: !!tableFilters?.tableGroupBy?.value,
        params: {
            group_by_options: [tableFilters?.tableGroupBy?.value] as string[],
            output_options: data?.output_options?.map(item => item.name),
            date_options: {
                from_date: fromDate,
                to_date: toDate,
            },
            where_options: whereOptions,
        },
        useCase,
        namespace,
    });

    const mappingOutputKeys = useMemo(
        () =>
            data?.output_options?.reduce(
                (acc, item) => ({
                    ...acc,
                    [item.name]: item.display_value,
                }),
                {},
            ) ?? {},
        [data?.output_options],
    );
    const { mappingGroupByKeys, groupByFilter } = useMemo(
        () =>
            data?.group_by_options?.reduce(
                (acc, item) => ({
                    mappingGroupByKeys: {
                        ...acc.mappingGroupByKeys,
                        [item.column_name]: item.display_value,
                    },
                    groupByFilter: {
                        data:
                            tab === 'graph' && item.column_name === 'date'
                                ? acc.groupByFilter.data
                                : [
                                      ...acc.groupByFilter.data,
                                      {
                                          label: item.display_value,
                                          value: item.column_name,
                                      },
                                  ],
                        type: tab === 'graph' ? 'multiSelect' : 'select',
                    },
                }),
                {
                    mappingGroupByKeys: {},
                    groupByFilter: {
                        data: [],
                        type: tab === 'graph' ? 'multiSelect' : 'select',
                    },
                },
            ) ?? {
                mappingGroupByKeys: {},
                groupByFilter: {
                    data: [],
                    type: tab === 'graph' ? 'multiSelect' : 'select',
                },
            },
        [data?.group_by_options, tab],
    );

    const mappingWhereKeys = useMemo(
        () =>
            data?.where_options?.reduce(
                (acc, item) => ({
                    ...acc,
                    [item.column_name]: item.display_value,
                }),
                {},
            ) ?? {},
        [data?.where_options],
    );

    const { filterData } = useMemo(
        () =>
            data?.where_options?.reduce(
                (acc, item) => ({
                    filterData: {
                        ...acc.filterData,
                        [item.column_name]: {
                            data: item.values,
                            type: 'multiSelect',
                        },
                    },
                }),
                {
                    filterData: {} as NewTypeSelectData,
                },
            ) ?? { filterData: {} as NewTypeSelectData },
        [data?.where_options],
    );

    const { filteredData, filteredValues } = useMemo(() => {
        if (tab === 'table')
            return {
                filteredValues: {
                    ...Object.entries(filters).reduce(
                        (acc: Partial<Record<string, NewSelectPossibleValues>>, [k, v]) =>
                            k === 'graphGroupBy' ? acc : { ...acc, [k]: v },
                        {},
                    ),
                    ...tableFilters,
                },
                filteredData: [
                    {
                        tableGroupBy: groupByFilter,
                        ...filterData,
                    } as NewTypeSelectData,
                    ...tableFiltersData,
                ],
            };
        return {
            filteredValues: filters,
            filteredData: [
                {
                    graphGroupBy: groupByFilter,
                    ...filterData,
                } as NewTypeSelectData,
            ],
        };
    }, [filterData, filters, groupByFilter, tab, tableFilters, tableFiltersData]);

    const BE_FILTERS = useMemo(
        () => data?.group_by_options?.map(item => item.column_name) ?? [],
        [data?.group_by_options],
    );

    return (
        <div className="pl-7">
            <SpaceportCommonHeader
                title="Insights"
                containerClassName="pl-[27px]"
                description="Monitor model health & usage metrics over time, by use case."
                actionsElement={
                    <div className="flex">
                        <NamespaceDropdown
                            selectedNamespace={namespace}
                            updateSelectedNamespace={e => {
                                setFilters({});
                                setTableFilters({
                                    tableGroupBy: { type: 'select', value: 'date' },
                                });
                                setNamespace(e);
                                handleURLParamsChange({
                                    multipleValues: {
                                        chartKey: 'orders_influenced',
                                        namespace: e,
                                    },
                                });
                            }}
                        />
                        <UseCaseDropdown
                            selectedNamespace={namespace}
                            selectedModule={useCase}
                            updateSelectedModule={e => {
                                setUseCase(e as InspectUseCase);
                                handleURLParamsChange({
                                    multipleValues: {
                                        chartKey: 'orders_influenced',
                                        usecase: e,
                                    },
                                });
                            }}
                        />
                    </div>
                }
            />
            <StyledTab.Wrapper<Tabs>
                values={[
                    {
                        value: 'graph',
                        component: (
                            <Graph
                                data={data}
                                mappingOutputKeys={mappingOutputKeys}
                                fromDate={fromDate}
                                toDate={toDate}
                                loading={isLoading}
                                whereOptions={whereOptions}
                                groupBy={(filters?.graphGroupBy?.value as string[]) ?? []}
                                useCase={useCase}
                                namespace={namespace}
                            />
                        ),
                    },
                    {
                        value: 'table',
                        component: (
                            <Table
                                data={table}
                                loading={isLoading || tableDataLoading || isLoading}
                                mappingGroupByKeys={{ ...mappingWhereKeys, ...mappingGroupByKeys }}
                                getTableFilters={setTableFiltersData}
                                filters={tableFilters}
                            />
                        ),
                    },
                ]}
                onChange={e => {
                    handleURLParamsChange({ key: 'tabQ', value: e });
                    setTab(e);
                }}
                value={tab}
            >
                <StyledTab.Nav />
                <div className="flex h-fit">
                    <MultiLevelSelect
                        data={filteredData}
                        loading={isLoading}
                        values={filteredValues}
                        onChange={e => {
                            const { filtersTable, filtersBE } = Object.entries(e).reduce(
                                (acc, [key, val]) =>
                                    ['graphGroupBy', ...BE_FILTERS].includes(key)
                                        ? { ...acc, filtersBE: { ...acc.filtersBE, [key]: val } }
                                        : {
                                              ...acc,
                                              filtersTable: { ...acc.filtersTable, [key]: val },
                                          },
                                {
                                    filtersTable: {} as typeof tableFilters,
                                    filtersBE: {} as typeof filters,
                                },
                            );
                            setFilters(filtersBE);
                            if (tab === 'table') setTableFilters(filtersTable);
                        }}
                        itemsFormattingFunction={e =>
                            ({
                                ...mappingGroupByKeys,
                                ...mappingWhereKeys,
                                tableGroupBy: 'group by',
                                graphGroupBy: 'group by',
                            })?.[e] ?? e
                        }
                        maxHeight={40}
                        targetClassName="ml-auto"
                        maxWidth="calc(100vw - 730px)"
                        disableGroupMultiSelectedItems
                    />
                    <DatePicker
                        minDate={fromDate}
                        maxDate={toDate}
                        handleChange={(startDate, endDate) =>
                            setDate({ fromDate: startDate, toDate: endDate })
                        }
                        isVladDesign
                    />
                </div>
                <StyledTab.Main />
            </StyledTab.Wrapper>
        </div>
    );
}
