import React from 'react';
import _ from 'lodash';
import { Spinner } from 'andromeda';
import { CardsContainer } from './CardsContainer';
import { useMarketPerformanceContext } from '../context/MarketPerformanceContextProvider';
import { TITLES_KEY_ORDERS } from '../constants';

export const Categories = () => {
    const { marketIntelligenceDiveData, marketIntelligenceDiveDataIsLoading, selectedPath } =
        useMarketPerformanceContext();
    const groupedCategories = _.groupBy(marketIntelligenceDiveData, 'level');
    const categories = TITLES_KEY_ORDERS.map(key => {
        if (groupedCategories[key]) {
            return <CardsContainer key={key} allCardsData={groupedCategories[key]} title={key} />;
        }
        return null;
    });
    return (
        <div className="flex-1 overflow-auto border-l-1 border-l-border-color px-4 pb-6 pt-2 text-xs font-medium text-navy">
            {!marketIntelligenceDiveDataIsLoading && categories}
            {marketIntelligenceDiveDataIsLoading && <Spinner className="!h-36" />}
        </div>
    );
};
