import useApiQuery from '@/hooks/useApiQuery';
import { PROMO_API_URL } from 'buildConfig/processEnv';

interface RequestBody {
    query: string;
    size: string;
    UPC: string;
    state: string;
}

interface AlertFeedback {
    message: string;
    positive: boolean;
    provider: string;
}

export interface AlertItem {
    title: string;
    message: string;
    noted: boolean;
    UPC: string;
    id: string;
    state: string;
    name: string;
    brand: string;
    alert: boolean;
    score: number;
    type: any;
    banner: any;
    size: string;
    confidence: string;
    analyze_messages: AnalyzeMessage[];
    alert_feedback: AlertFeedback[];
    created_at: string;
}

interface AnalyzeMessage {
    message: string;
    type: string;
}

export const GET_ALERTS_URL = `${PROMO_API_URL}/promo-management/api/promo-management/get_alerts`;

interface UseAlertsParams extends Partial<RequestBody> {
    queryKey?: string;
}

export const useAlerts = ({ query, size, UPC, state, queryKey }: UseAlertsParams) =>
    useApiQuery<AlertItem[]>({
        queryKeys: [queryKey],
        url: GET_ALERTS_URL,
        method: 'POST',
        body: {
            query,
            size,
            UPC,
            state,
        },
    });
