import { StyledChartContainer } from '@/components/promos/utils';
import BarChart from '@/components/utils/charts/barChart';
import React from 'react';
import { useProductAnalysisContext } from '../context/ProductAnalysisContextProvider';
import { getYearOverYearChartData } from '../utils';
import { useYearOverYearChartData } from '../hooks/useYearOverYearChartData';
import { YEAROVERYEAR_CHART_METRIC_LABEL } from '../constants';
import { ChartLegends } from './ChartLegends';

export const YearOverYearChart = () => {
    const { timeseriesAnalysisData, timeseriesAnalysisIsLoading } = useProductAnalysisContext();
    const timeseriesAnalysisDataForYearOverYearChart =
        getYearOverYearChartData(timeseriesAnalysisData);
    const { chartData } = useYearOverYearChartData(timeseriesAnalysisDataForYearOverYearChart);

    return (
        <StyledChartContainer
            title={
                <div className="text-xs font-medium text-navy">Year over Year - % Difference</div>
            }
            className="!h-[59vh] !w-full"
            chartClassName="!h-[49vh]"
            loading={timeseriesAnalysisIsLoading}
            loaderClassName="!w-full !h-[350px]"
        >
            <>
                <BarChart
                    data={chartData}
                    legend={{ display: false }}
                    tooltip={{
                        callbacks: {
                            label(tooltipItem) {
                                return `${tooltipItem.dataset.label} : ${tooltipItem.formattedValue}%`;
                            },
                        },
                    }}
                    layout={{
                        padding: {
                            bottom: 20,
                        },
                    }}
                />
                <ChartLegends
                    datasets={chartData.datasets}
                    chartMetricLabels={YEAROVERYEAR_CHART_METRIC_LABEL}
                    metricKeySuffix="_yoy"
                />
            </>
        </StyledChartContainer>
    );
};
