import useRouterQuery from '@/hooks/useRouterQuery';
import { useState } from 'react';
import { DEFAULT_PRODUCT_UPC, TIMEFRAME_OPTIONS } from '../constants';
import { ProductAnalysisPageQueryParams } from '../types';
import { getMultiSelectToggleMetricOptions } from '../utils';

export const useProductAnalysisQueryParams = () => {
    const { handleURLParamsChange, router } = useRouterQuery();
    const { metrics, product, timeframe } = router.query as ProductAnalysisPageQueryParams;
    const [productState, setProductState] = useState(product ?? DEFAULT_PRODUCT_UPC);

    const metricsValue = () => {
        if (!metrics) return [getMultiSelectToggleMetricOptions('')[0].value];
        if (typeof metrics === 'string') return [metrics];
        return metrics;
    };
    const [metricsState, setMetricsState] = useState(() => metricsValue());

    const updateTimeframe = (value: string) => {
        handleURLParamsChange({ key: 'timeframe', value });
    };

    const updateProduct = (value: string) => {
        setProductState(value);
        handleURLParamsChange({ key: 'product', value });
    };

    const updateSecondProduct = (value: string) => {
        handleURLParamsChange({ key: 'secondProduct', value });
    };

    const updateMetrics = (value: string[]) => {
        setMetricsState(value);
        handleURLParamsChange({ key: 'metrics', value });
    };

    const updateState = (value?: string) => {
        handleURLParamsChange({ key: 'state', value });
    };

    return {
        queryParams: {
            ...(router.query as ProductAnalysisPageQueryParams),
            metrics: metricsState,
            timeframe: timeframe ?? TIMEFRAME_OPTIONS[2].value,
            product: productState,
        },
        updateTimeframe,
        updateProduct,
        updateMetrics,
        updateState,
        updateSecondProduct,
    };
};
