import dynamic from 'next/dynamic';
import ResponsiveLayout from '@/components/responsive/ResponsiveLayout';
import { HERMES_USER_GROUP_WHITELIST } from '@/components/navBar/constants';
import { UserGuard } from '@/components/utils/UserGuard';

const PipelineContainer = dynamic(import('../components/pipeline/pipelineContainer'), {
    ssr: false,
});

const Hermes = () => (
    <ResponsiveLayout title="Data Ingestion">
        <UserGuard userGroupWhitelist={HERMES_USER_GROUP_WHITELIST}>
            <PipelineContainer />
        </UserGuard>
    </ResponsiveLayout>
);

export default Hermes;
