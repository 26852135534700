import React, { FC } from 'react';
import { ColorSquare } from 'andromeda';
import { findDatasetByLabel, getProductNameWithSizeFromProductList } from '../utils';
import { useProductAnalysisContext } from '../context/ProductAnalysisContextProvider';

interface ChartLegendsProps {
    chartMetricLabels: Record<string, string>;
    datasets: any[];
    metricKeySuffix?: string;
}

export const ChartLegends: FC<ChartLegendsProps> = ({
    chartMetricLabels,
    datasets,
    metricKeySuffix = '',
}) => {
    const { metrics, selectedProducts, productList, competitors } = useProductAnalysisContext();

    if (datasets.length > 0) {
        return (
            <div className="grid grid-cols-1 gap-1 pl-[52px]">
                {selectedProducts.map(product => {
                    const productName = getProductNameWithSizeFromProductList(
                        productList,
                        competitors,
                        product,
                    );

                    return (
                        <div key={product} className="flex items-center gap-4">
                            <div className="text-xs font-normal text-navy-solid-70">
                                {productName}
                            </div>
                            <div className="flex items-center gap-2">
                                {metrics.map(metric => {
                                    const metricKey = `${metric}${metricKeySuffix}`;
                                    const foundDataset = findDatasetByLabel(
                                        `${chartMetricLabels[metricKey]} - ${productName}`,
                                        datasets,
                                    );

                                    return (
                                        <div key={metric} className="flex items-center gap-2">
                                            <ColorSquare
                                                color={foundDataset?.backgroundColor}
                                                size={8}
                                                variant={foundDataset?.legendDash}
                                            />
                                            <div className="text-xs font-normal text-navy-solid-50">
                                                {chartMetricLabels[metricKey]}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
    return null;
};
